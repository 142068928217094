
const getHost = () => process.env.REACT_APP_QAOS_SERVER_HOST;
const getAuthorization = () =>
  localStorage.getItem('authenticationBearer') &&
    localStorage.getItem('authenticationBearerUpdatedAt') &&
    Date.now() - localStorage.getItem('authenticationBearerUpdatedAt') < 1000 * 60 * 60 * 24 * 30
    ? `Bearer ${localStorage.getItem('authenticationBearer')}` : undefined;


async function parseResponse(response) {
  if (!response.ok) {
    const error = new Error(`HTTP error! status: ${response.status} HHHHHHH`);
    error.status = response.status;
    throw error;
  }

  return response.json();
}

function wrapClean(fn) {
  return async (...args) => {
    try {
      return await fn(...args);
    } catch (error) {
      if (error?.status === 401) {
        localStorage.removeItem('authenticationBearer');
        localStorage.removeItem('authenticationBearerUpdatedAt');
        localStorage.removeItem('countryCode2Letter');
        localStorage.removeItem('countryCode2LetterUpdatedAt');

        return fn(...args);
      }
    }
  }
}

function wrapWithAuth(fn) {
  return async (...args) => {
    if (!getAuthorization()) {
      await wrapClean(apiConnect)(...args);
    }

    return wrapClean(fn)(...args);
  }
}

export const apiConnect = wrapClean(async () => {
  const response = await fetch(`${getHost()}/api/connect`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getAuthorization(),
    },
    body: JSON.stringify({}),
  });

  const result = await parseResponse(response);

  // Save token and country code
  localStorage.setItem('authenticationBearer', result.data.token);
  localStorage.setItem('authenticationBearerUpdatedAt', Date.now());
  localStorage.setItem('countryCode2Letter', result.data.user.countryCode || '');
  localStorage.setItem('countryCode2LetterUpdatedAt', Date.now());
  localStorage.setItem('userId', result.data.user.id);

  return result;
});

export const apiGetMyGeolocation = wrapWithAuth(async () => {
  const response = await fetch(`${getHost()}/api/geolocation`, {
    method: 'GET',
    'Authorization': getAuthorization(),
  });

  return parseResponse(response);
})

export const apiGetTweets = wrapWithAuth(async ({ tag, index }) => {
  const response = await fetch(`${getHost()}/api/stream/${tag}?index=${index || 0}`, {
    method: 'GET',
    headers: { 'Authorization': getAuthorization() },
  });

  return parseResponse(response);
});

export const apiGetTweetsSse = wrapWithAuth(async ({ tag }) => {
  const bearerToken = localStorage.getItem('authenticationBearer') || '';
  const url = new URL(`${getHost()}/api/listen/${tag}`);

  // Due to browser restrictions, we need to pass the token as a query parameter
  url.searchParams.append('token', encodeURIComponent(bearerToken));

  return new EventSource(url.toString());
});

export const apiPostTweet = wrapWithAuth(async (tweet) => {
  const response = await fetch(`${getHost()}/api/tweets`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getAuthorization(),
    },
    body: JSON.stringify({ tweet }),
  });

  return parseResponse(response);
});

export const apiGetTweet = wrapWithAuth(async (id) => {
  const response = await fetch(`${getHost()}/api/tweets/id/${id}`, {
    method: 'GET',
    headers: { 'Authorization': getAuthorization() },
  });

  return parseResponse(response);
});

export const apiUpdateWebPushSubscription = wrapWithAuth(async ({ webPushSubscription }) => {
  const response = await fetch(`${getHost()}/api/web-push`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': getAuthorization(),
    },
    body: JSON.stringify({ webPushSubscription }),
  });

  return parseResponse(response);
});

export const apiGetSubscribedTags = wrapWithAuth(async () => {
  const response = await fetch(`${getHost()}/api/subscriptions`, {
    method: 'GET',
    headers: { 'Authorization': getAuthorization() },
  });

  return parseResponse(response);
});

export const apiSubscribeToTag = wrapWithAuth(async ({ tag }) => {
  const response = await fetch(`${getHost()}/api/subscriptions/${tag}`, {
    method: 'POST',
    headers: { 'Authorization': getAuthorization() },
  });

  return parseResponse(response);
});

export const apiUnsubscribeTag = wrapWithAuth(async ({ tag }) => {
  const response = await fetch(`${getHost()}/api/subscriptions/${tag}`, {
    method: 'DELETE',
    headers: { 'Authorization': getAuthorization() },
  });

  return parseResponse(response);
});
