
export const countries = [
  {
    name: "Afghanistan",
    dialCode: "93",
    iso2: "AF",
    iso3: "AFG",
    emoji: "🇦🇫",
    capital: "Kabul",
    timezone: [
      "Asia/Kabul"
    ],
    utcOffset: [
      "+04:30"
    ],
    location: {
      lat: 33.93911,
      lng: 67.709953
    }
  },

  {
    name: "Åland Islands",
    dialCode: "358",
    iso2: "AX",
    iso3: "ALA",
    emoji: "🇦🇽",
    capital: "Mariehamn",
    timezone: [
      "Europe/Helsinki"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: 60.178524,
      lng: 19.91561
    }
  },

  {
    name: "Albania",
    dialCode: "355",
    iso2: "AL",
    iso3: "ALB",
    emoji: "🇦🇱",
    capital: "Tirana",
    timezone: [
      "Europe/Tirane"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 41.153332,
      lng: 20.168331
    }
  },

  {
    name: "Algeria",
    dialCode: "213",
    iso2: "DZ",
    iso3: "DZA",
    emoji: "🇩🇿",
    capital: "Algiers",
    timezone: [
      "Africa/Algiers"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 28.033886,
      lng: 1.659626
    }
  },

  {
    name: "American Samoa",
    dialCode: "1684",
    iso2: "AS",
    iso3: "ASM",
    emoji: "🇦🇸",
    capital: "Pago Pago",
    timezone: [
      "Pacific/Pago_Pago"
    ],
    utcOffset: [
      "-11:00"
    ],
    location: {
      lat: -14.270972,
      lng: -170.132217
    }
  },

  {
    name: "Andorra",
    dialCode: "376",
    iso2: "AD",
    iso3: "AND",
    emoji: "🇦🇩",
    capital: "Andorra la Vella",
    timezone: [
      "Europe/Andorra"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 42.546245,
      lng: 1.601554
    }
  },

  {
    name: "Angola",
    dialCode: "244",
    iso2: "AO",
    iso3: "AGO",
    emoji: "🇦🇴",
    capital: "Luanda",
    timezone: [
      "Africa/Lagos"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: -11.202692,
      lng: 17.873887
    }
  },

  {
    name: "Anguilla",
    dialCode: "1264",
    iso2: "AI",
    iso3: "AIA",
    emoji: "🇦🇮",
    capital: "The Valley",
    timezone: [
      "America/Puerto_Rico"
    ],
    utcOffset: [
      "-04:00"
    ],
    location: {
      lat: 18.220554,
      lng: -63.068615
    }
  },

  {
    name: "Antarctica",
    dialCode: "672",
    iso2: "AQ",
    iso3: "ATA",
    emoji: "🇦🇶",
    capital: null,
    timezone: [
      "Antarctica/Casey",
      "Antarctica/Davis",
      "Antarctica/Mawson",
      "Antarctica/Palmer",
      "Antarctica/Rothera",
      "Antarctica/Troll",
      "Antarctica/Vostok",
      "Asia/Riyadh",
      "Pacific/Auckland",
      "Pacific/Port_Moresby"
    ],
    utcOffset: [
      "+11:00",
      "+07:00",
      "+05:00",
      "-03:00",
      "-03:00",
      "+00:00",
      "+06:00",
      "+03:00",
      "+12:00",
      "+10:00"
    ],
    location: {
      lat: -75.250973,
      lng: -0.071389
    }
  },

  {
    name: "Antigua & Barbuda",
    dialCode: "1268",
    iso2: "AG",
    iso3: "ATG",
    emoji: "🇦🇬",
    capital: "St. John's",
    timezone: [
      "America/Puerto_Rico"
    ],
    utcOffset: [
      "-04:00"
    ],
    location: {
      lat: 17.060816,
      lng: -61.796428
    }
  },

  {
    name: "Argentina",
    dialCode: "54",
    iso2: "AR",
    iso3: "ARG",
    emoji: "🇦🇷",
    capital: "Buenos Aires",
    timezone: [
      "America/Argentina/Buenos_Aires",
      "America/Argentina/Catamarca",
      "America/Argentina/Cordoba",
      "America/Argentina/Jujuy",
      "America/Argentina/La_Rioja",
      "America/Argentina/Mendoza",
      "America/Argentina/Rio_Gallegos",
      "America/Argentina/Salta",
      "America/Argentina/San_Juan",
      "America/Argentina/San_Luis",
      "America/Argentina/Tucuman",
      "America/Argentina/Ushuaia"
    ],
    utcOffset: [
      "-03:00",
      "-03:00",
      "-03:00",
      "-03:00",
      "-03:00",
      "-03:00",
      "-03:00",
      "-03:00",
      "-03:00",
      "-03:00",
      "-03:00",
      "-03:00"
    ],
    location: {
      lat: -38.416097,
      lng: -63.616672
    }
  },

  {
    name: "Armenia",
    dialCode: "374",
    iso2: "AM",
    iso3: "ARM",
    emoji: "🇦🇲",
    capital: "Yerevan",
    timezone: [
      "Asia/Yerevan"
    ],
    utcOffset: [
      "+04:00"
    ],
    location: {
      lat: 40.069099,
      lng: 45.038189
    }
  },

  {
    name: "Aruba",
    dialCode: "297",
    iso2: "AW",
    iso3: "ABW",
    emoji: "🇦🇼",
    capital: "Oranjestad",
    timezone: [
      "America/Puerto_Rico"
    ],
    utcOffset: [
      "-04:00"
    ],
    location: {
      lat: 12.52111,
      lng: -69.968338
    }
  },

  {
    name: "Australia",
    dialCode: "61",
    iso2: "AU",
    iso3: "AUS",
    emoji: "🇦🇺",
    capital: "Canberra",
    timezone: [
      "Antarctica/Macquarie",
      "Australia/Adelaide",
      "Australia/Brisbane",
      "Australia/Broken_Hill",
      "Australia/Darwin",
      "Australia/Eucla",
      "Australia/Hobart",
      "Australia/Lindeman",
      "Australia/Lord_Howe",
      "Australia/Melbourne",
      "Australia/Perth",
      "Australia/Sydney"
    ],
    utcOffset: [
      "+10:00",
      "+09:30",
      "+10:00",
      "+09:30",
      "+09:30",
      "+08:45",
      "+10:00",
      "+10:00",
      "+10:30",
      "+10:00",
      "+08:00",
      "+10:00"
    ],
    location: {
      lat: -25.274398,
      lng: 133.775136
    }
  },

  {
    name: "Austria",
    dialCode: "43",
    iso2: "AT",
    iso3: "AUT",
    emoji: "🇦🇹",
    capital: "Vienna",
    timezone: [
      "Europe/Vienna"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 47.516231,
      lng: 14.550072
    }
  },

  {
    name: "Azerbaijan",
    dialCode: "994",
    iso2: "AZ",
    iso3: "AZE",
    emoji: "🇦🇿",
    capital: "Baku",
    timezone: [
      "Asia/Baku"
    ],
    utcOffset: [
      "+04:00"
    ],
    location: {
      lat: 40.143105,
      lng: 47.576927
    }
  },

  {
    name: "Bahamas",
    dialCode: "1242",
    iso2: "BS",
    iso3: "BHS",
    emoji: "🇧🇸",
    capital: "Nassau",
    timezone: [
      "America/Toronto"
    ],
    utcOffset: [
      "-05:00"
    ],
    location: {
      lat: 25.03428,
      lng: -77.39628
    }
  },

  {
    name: "Bahrain",
    dialCode: "973",
    iso2: "BH",
    iso3: "BHR",
    emoji: "🇧🇭",
    capital: "Manama",
    timezone: [
      "Asia/Qatar"
    ],
    utcOffset: [
      "+03:00"
    ],
    location: {
      lat: 25.930414,
      lng: 50.637772
    }
  },

  {
    name: "Bangladesh",
    dialCode: "880",
    iso2: "BD",
    iso3: "BGD",
    emoji: "🇧🇩",
    capital: "Dhaka",
    timezone: [
      "Asia/Dhaka"
    ],
    utcOffset: [
      "+06:00"
    ],
    location: {
      lat: 23.684994,
      lng: 90.356331
    }
  },

  {
    name: "Barbados",
    dialCode: "1246",
    iso2: "BB",
    iso3: "BRB",
    emoji: "🇧🇧",
    capital: "Bridgetown",
    timezone: [
      "America/Barbados"
    ],
    utcOffset: [
      "-04:00"
    ],
    location: {
      lat: 13.193887,
      lng: -59.543198
    }
  },

  {
    name: "Belarus",
    dialCode: "375",
    iso2: "BY",
    iso3: "BLR",
    emoji: "🇧🇾",
    capital: "Minsk",
    timezone: [
      "Europe/Minsk"
    ],
    utcOffset: [
      "+03:00"
    ],
    location: {
      lat: 53.709807,
      lng: 27.953389
    }
  },

  {
    name: "Belgium",
    dialCode: "32",
    iso2: "BE",
    iso3: "BEL",
    emoji: "🇧🇪",
    capital: "Brussels",
    timezone: [
      "Europe/Brussels"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 50.503887,
      lng: 4.469936
    }
  },

  {
    name: "Belize",
    dialCode: "501",
    iso2: "BZ",
    iso3: "BLZ",
    emoji: "🇧🇿",
    capital: "Belmopan",
    timezone: [
      "America/Belize"
    ],
    utcOffset: [
      "-06:00"
    ],
    location: {
      lat: 17.189877,
      lng: -88.49765
    }
  },

  {
    name: "Benin",
    dialCode: "229",
    iso2: "BJ",
    iso3: "BEN",
    emoji: "🇧🇯",
    capital: "Porto-Novo",
    timezone: [
      "Africa/Lagos"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 9.30769,
      lng: 2.315834
    }
  },

  {
    name: "Bermuda",
    dialCode: "1441",
    iso2: "BM",
    iso3: "BMU",
    emoji: "🇧🇲",
    capital: "Hamilton",
    timezone: [
      "Atlantic/Bermuda"
    ],
    utcOffset: [
      "-04:00"
    ],
    location: {
      lat: 32.321384,
      lng: -64.75737
    }
  },

  {
    name: "Bhutan",
    dialCode: "975",
    iso2: "BT",
    iso3: "BTN",
    emoji: "🇧🇹",
    capital: "Thimphu",
    timezone: [
      "Asia/Thimphu"
    ],
    utcOffset: [
      "+06:00"
    ],
    location: {
      lat: 27.514162,
      lng: 90.433601
    }
  },

  {
    name: "Bolivia",
    dialCode: "591",
    iso2: "BO",
    iso3: "BOL",
    emoji: "🇧🇴",
    capital: "Sucre",
    timezone: [
      "America/La_Paz"
    ],
    utcOffset: [
      "-04:00"
    ],
    location: {
      lat: -16.290154,
      lng: -63.588653
    }
  },

  {
    name: "Bosnia",
    dialCode: "387",
    iso2: "BA",
    iso3: "BIH",
    emoji: "🇧🇦",
    capital: "Sarajevo",
    timezone: [
      "Europe/Belgrade"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 43.915886,
      lng: 17.679076
    }
  },

  {
    name: "Botswana",
    dialCode: "267",
    iso2: "BW",
    iso3: "BWA",
    emoji: "🇧🇼",
    capital: "Gaborone",
    timezone: [
      "Africa/Maputo"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: -22.328474,
      lng: 24.684866
    }
  },

  {
    name: "Bouvet Island",
    dialCode: null,
    iso2: "BV",
    iso3: "BVT",
    emoji: "🇧🇻",
    capital: null,
    timezone: [
      "Antarctica/Bouvet"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: -54.423199,
      lng: 3.413194
    }
  },

  {
    name: "Brazil",
    dialCode: "55",
    iso2: "BR",
    iso3: "BRA",
    emoji: "🇧🇷",
    capital: "Brasilia",
    timezone: [
      "America/Araguaina",
      "America/Bahia",
      "America/Belem",
      "America/Boa_Vista",
      "America/Campo_Grande",
      "America/Cuiaba",
      "America/Eirunepe",
      "America/Fortaleza",
      "America/Maceio",
      "America/Manaus",
      "America/Noronha",
      "America/Porto_Velho",
      "America/Recife",
      "America/Rio_Branco",
      "America/Santarem",
      "America/Sao_Paulo"
    ],
    utcOffset: [
      "-03:00",
      "-03:00",
      "-03:00",
      "-04:00",
      "-04:00",
      "-04:00",
      "-05:00",
      "-03:00",
      "-03:00",
      "-04:00",
      "-02:00",
      "-04:00",
      "-03:00",
      "-05:00",
      "-03:00",
      "-03:00"
    ],
    location: {
      lat: -14.235004,
      lng: -51.92528
    }
  },

  {
    name: "British Indian Ocean Territory",
    dialCode: "246",
    iso2: "IO",
    iso3: "IOT",
    emoji: "🇮🇴",
    capital: "Diego Garcia",
    timezone: [
      "Indian/Chagos"
    ],
    utcOffset: [
      "+06:00"
    ],
    location: {
      lat: -6.343194,
      lng: 71.876519
    }
  },

  {
    name: "Brunei",
    dialCode: "673",
    iso2: "BN",
    iso3: "BRN",
    emoji: "🇧🇳",
    capital: "Bandar Seri Begawan",
    timezone: [
      "Asia/Brunei"
    ],
    utcOffset: [
      "+08:00"
    ],
    location: {
      lat: 4.535277,
      lng: 114.727669
    }
  },

  {
    name: "Bulgaria",
    dialCode: "359",
    iso2: "BG",
    iso3: "BGR",
    emoji: "🇧🇬",
    capital: "Sofia",
    timezone: [
      "Europe/Sofia"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: 42.733883,
      lng: 25.48583
    }
  },

  {
    name: "Burkina Faso",
    dialCode: "226",
    iso2: "BF",
    iso3: "BFA",
    emoji: "🇧🇫",
    capital: "Ouagadougou",
    timezone: [
      "Africa/Abidjan"
    ],
    utcOffset: [
      "+00:00"
    ],
    location: {
      lat: 12.238333,
      lng: -1.561593
    }
  },

  {
    name: "Burundi",
    dialCode: "257",
    iso2: "BI",
    iso3: "BDI",
    emoji: "🇧🇮",
    capital: "Bujumbura",
    timezone: [
      "Africa/Maputo"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: -3.373056,
      lng: 29.918886
    }
  },

  {
    name: "Cambodia",
    dialCode: "855",
    iso2: "KH",
    iso3: "KHM",
    emoji: "🇰🇭",
    capital: "Phnom Penh",
    timezone: [
      "Asia/Bangkok"
    ],
    utcOffset: [
      "+07:00"
    ],
    location: {
      lat: 12.565679,
      lng: 104.990963
    }
  },

  {
    name: "Cameroon",
    dialCode: "237",
    iso2: "CM",
    iso3: "CMR",
    emoji: "🇨🇲",
    capital: "Yaounde",
    timezone: [
      "Africa/Lagos"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 7.369722,
      lng: 12.354722
    }
  },

  {
    name: "Canada",
    dialCode: "1",
    iso2: "CA",
    iso3: "CAN",
    emoji: "🇨🇦",
    capital: "Ottawa",
    timezone: [
      "America/Cambridge_Bay",
      "America/Dawson",
      "America/Dawson_Creek",
      "America/Edmonton",
      "America/Fort_Nelson",
      "America/Glace_Bay",
      "America/Goose_Bay",
      "America/Halifax",
      "America/Inuvik",
      "America/Iqaluit",
      "America/Moncton",
      "America/Nipigon",
      "America/Panama",
      "America/Pangnirtung",
      "America/Phoenix",
      "America/Puerto_Rico",
      "America/Rainy_River",
      "America/Rankin_Inlet",
      "America/Regina",
      "America/Resolute",
      "America/St_Johns",
      "America/Swift_Current",
      "America/Thunder_Bay",
      "America/Toronto",
      "America/Vancouver",
      "America/Whitehorse",
      "America/Winnipeg",
      "America/Yellowknife"
    ],
    utcOffset: [
      "-07:00",
      "-07:00",
      "-07:00",
      "-07:00",
      "-07:00",
      "-04:00",
      "-04:00",
      "-04:00",
      "-07:00",
      "-05:00",
      "-04:00",
      "-05:00",
      "-05:00",
      "-05:00",
      "-07:00",
      "-04:00",
      "-06:00",
      "-06:00",
      "-06:00",
      "-06:00",
      "-03:30",
      "-06:00",
      "-05:00",
      "-05:00",
      "-08:00",
      "-07:00",
      "-06:00",
      "-07:00"
    ],
    location: {
      lat: 56.130366,
      lng: -106.346771
    }
  },

  {
    name: "Cape Verde",
    dialCode: "238",
    iso2: "CV",
    iso3: "CPV",
    emoji: "🇨🇻",
    capital: "Praia",
    timezone: [
      "Atlantic/Cape_Verde"
    ],
    utcOffset: [
      "-01:00"
    ],
    location: {
      lat: 16.002082,
      lng: -24.013197
    }
  },

  {
    name: "Cayman Islands",
    dialCode: " 345",
    iso2: "KY",
    iso3: "CYM",
    emoji: "🇰🇾",
    capital: "George Town",
    timezone: [
      "America/Panama"
    ],
    utcOffset: [
      "-05:00"
    ],
    location: {
      lat: 19.513469,
      lng: -80.566956
    }
  },

  {
    name: "Central African Republic",
    dialCode: "236",
    iso2: "CF",
    iso3: "CAF",
    emoji: "🇨🇫",
    capital: "Bangui",
    timezone: [
      "Africa/Lagos"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 6.611111,
      lng: 20.939444
    }
  },

  {
    name: "Chad",
    dialCode: "235",
    iso2: "TD",
    iso3: "TCD",
    emoji: "🇹🇩",
    capital: "N'Djamena",
    timezone: [
      "Africa/Ndjamena"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 15.454166,
      lng: 18.732207
    }
  },

  {
    name: "Chile",
    dialCode: "56",
    iso2: "CL",
    iso3: "CHL",
    emoji: "🇨🇱",
    capital: "Santiago",
    timezone: [
      "America/Punta_Arenas",
      "America/Santiago",
      "Pacific/Easter"
    ],
    utcOffset: [
      "-03:00",
      "-04:00",
      "-06:00"
    ],
    location: {
      lat: -35.675147,
      lng: -71.542969
    }
  },

  {
    name: "China",
    dialCode: "86",
    iso2: "CN",
    iso3: "CHN",
    emoji: "🇨🇳",
    capital: "Beijing",
    timezone: [
      "Asia/Shanghai",
      "Asia/Urumqi"
    ],
    utcOffset: [
      "+08:00",
      "+06:00"
    ],
    location: {
      lat: 35.86166,
      lng: 104.195397
    }
  },

  {
    name: "Christmas Island",
    dialCode: "61",
    iso2: "CX",
    iso3: "CXR",
    emoji: "🇨🇽",
    capital: "Flying Fish Cove",
    timezone: [
      "Indian/Christmas"
    ],
    utcOffset: [
      "+07:00"
    ],
    location: {
      lat: -10.447525,
      lng: 105.690449
    }
  },

  {
    name: "Cocos (Keeling) Islands",
    dialCode: "61",
    iso2: "CC",
    iso3: "CCK",
    emoji: "🇨🇨",
    capital: "West Island",
    timezone: [
      "Indian/Cocos"
    ],
    utcOffset: [
      "+06:30"
    ],
    location: {
      lat: -12.164165,
      lng: 96.870956
    }
  },

  {
    name: "Colombia",
    dialCode: "57",
    iso2: "CO",
    iso3: "COL",
    emoji: "🇨🇴",
    capital: "Bogota",
    timezone: [
      "America/Bogota"
    ],
    utcOffset: [
      "-05:00"
    ],
    location: {
      lat: 4.570868,
      lng: -74.297333
    }
  },

  {
    name: "Comoros",
    dialCode: "269",
    iso2: "KM",
    iso3: "COM",
    emoji: "🇰🇲",
    capital: "Moroni",
    timezone: [
      "Africa/Nairobi"
    ],
    utcOffset: [
      "+03:00"
    ],
    location: {
      lat: -11.875001,
      lng: 43.872219
    }
  },

  {
    name: "Congo - Brazzaville",
    dialCode: "242",
    iso2: "CG",
    iso3: "COG",
    emoji: "🇨🇬",
    capital: "Brazzaville",
    timezone: [
      "Africa/Lagos"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: -0.228021,
      lng: 15.827659
    }
  },

  {
    name: "Congo - Kinshasa",
    dialCode: "243",
    iso2: "CD",
    iso3: "COD",
    emoji: "🇨🇩",
    capital: "Kinshasa",
    timezone: [
      "Africa/Lagos",
      "Africa/Maputo"
    ],
    utcOffset: [
      "+01:00",
      "+02:00"
    ],
    location: {
      lat: -4.038333,
      lng: 21.758664
    }
  },

  {
    name: "Cook Islands",
    dialCode: "682",
    iso2: "CK",
    iso3: "COK",
    emoji: "🇨🇰",
    capital: "Avarua",
    timezone: [
      "Pacific/Rarotonga"
    ],
    utcOffset: [
      "-10:00"
    ],
    location: {
      lat: -21.236736,
      lng: -159.777671
    }
  },

  {
    name: "Costa Rica",
    dialCode: "506",
    iso2: "CR",
    iso3: "CRI",
    emoji: "🇨🇷",
    capital: "San Jose",
    timezone: [
      "America/Costa_Rica"
    ],
    utcOffset: [
      "-06:00"
    ],
    location: {
      lat: 9.748917,
      lng: -83.753428
    }
  },

  {
    name: "Côte d’Ivoire",
    dialCode: "225",
    iso2: "CI",
    iso3: "CIV",
    emoji: "🇨🇮",
    capital: "Yamoussoukro",
    timezone: [
      "Africa/Abidjan"
    ],
    utcOffset: [
      "+00:00"
    ],
    location: {
      lat: 7.539989,
      lng: -5.54708
    }
  },

  {
    name: "Croatia",
    dialCode: "385",
    iso2: "HR",
    iso3: "HRV",
    emoji: "🇭🇷",
    capital: "Zagreb",
    timezone: [
      "Europe/Belgrade"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 45.1,
      lng: 15.2
    }
  },

  {
    name: "Cuba",
    dialCode: "53",
    iso2: "CU",
    iso3: "CUB",
    emoji: "🇨🇺",
    capital: "Havana",
    timezone: [
      "America/Havana"
    ],
    utcOffset: [
      "-05:00"
    ],
    location: {
      lat: 21.521757,
      lng: -77.781167
    }
  },

  {
    name: "Cyprus",
    dialCode: "357",
    iso2: "CY",
    iso3: "CYP",
    emoji: "🇨🇾",
    capital: "Nicosia",
    timezone: [
      "Asia/Famagusta",
      "Asia/Nicosia"
    ],
    utcOffset: [
      "+02:00",
      "+02:00"
    ],
    location: {
      lat: 35.126413,
      lng: 33.429859
    }
  },

  {
    name: "Czechia",
    dialCode: "420",
    iso2: "CZ",
    iso3: "CZE",
    emoji: "🇨🇿",
    capital: "Prague",
    timezone: [
      "Europe/Prague"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 49.817492,
      lng: 15.472962
    }
  },

  {
    name: "Denmark",
    dialCode: "45",
    iso2: "DK",
    iso3: "DNK",
    emoji: "🇩🇰",
    capital: "Copenhagen",
    timezone: [
      "Europe/Copenhagen"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 56.26392,
      lng: 9.501785
    }
  },

  {
    name: "Djibouti",
    dialCode: "253",
    iso2: "DJ",
    iso3: "DJI",
    emoji: "🇩🇯",
    capital: "Djibouti",
    timezone: [
      "Africa/Nairobi"
    ],
    utcOffset: [
      "+03:00"
    ],
    location: {
      lat: 11.825138,
      lng: 42.590275
    }
  },

  {
    name: "Dominica",
    dialCode: "1767",
    iso2: "DM",
    iso3: "DMA",
    emoji: "🇩🇲",
    capital: "Roseau",
    timezone: [
      "America/Puerto_Rico"
    ],
    utcOffset: [
      "-04:00"
    ],
    location: {
      lat: 15.414999,
      lng: -61.370976
    }
  },

  {
    name: "Dominican Republic",
    dialCode: "1849",
    iso2: "DO",
    iso3: "DOM",
    emoji: "🇩🇴",
    capital: "Santo Domingo",
    timezone: [
      "America/Santo_Domingo"
    ],
    utcOffset: [
      "-04:00"
    ],
    location: {
      lat: 18.735693,
      lng: -70.162651
    }
  },

  {
    name: "Ecuador",
    dialCode: "593",
    iso2: "EC",
    iso3: "ECU",
    emoji: "🇪🇨",
    capital: "Quito",
    timezone: [
      "America/Guayaquil",
      "Pacific/Galapagos"
    ],
    utcOffset: [
      "-05:00",
      "-06:00"
    ],
    location: {
      lat: -1.831239,
      lng: -78.183406
    }
  },

  {
    name: "Egypt",
    dialCode: "20",
    iso2: "EG",
    iso3: "EGY",
    emoji: "🇪🇬",
    capital: "Cairo",
    timezone: [
      "Africa/Cairo"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: 26.820553,
      lng: 30.802498
    }
  },

  {
    name: "El Salvador",
    dialCode: "503",
    iso2: "SV",
    iso3: "SLV",
    emoji: "🇸🇻",
    capital: "San Salvador",
    timezone: [
      "America/El_Salvador"
    ],
    utcOffset: [
      "-06:00"
    ],
    location: {
      lat: 13.794185,
      lng: -88.89653
    }
  },

  {
    name: "Equatorial Guinea",
    dialCode: "240",
    iso2: "GQ",
    iso3: "GNQ",
    emoji: "🇬🇶",
    capital: "Malabo",
    timezone: [
      "Africa/Lagos"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 1.650801,
      lng: 10.267895
    }
  },

  {
    name: "Eritrea",
    dialCode: "291",
    iso2: "ER",
    iso3: "ERI",
    emoji: "🇪🇷",
    capital: "Asmara",
    timezone: [
      "Africa/Nairobi"
    ],
    utcOffset: [
      "+03:00"
    ],
    location: {
      lat: 15.179384,
      lng: 39.782334
    }
  },

  {
    name: "Estonia",
    dialCode: "372",
    iso2: "EE",
    iso3: "EST",
    emoji: "🇪🇪",
    capital: "Tallinn",
    timezone: [
      "Europe/Tallinn"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: 58.595272,
      lng: 25.013607
    }
  },

  {
    name: "Ethiopia",
    dialCode: "251",
    iso2: "ET",
    iso3: "ETH",
    emoji: "🇪🇹",
    capital: "Addis Ababa",
    timezone: [
      "Africa/Nairobi"
    ],
    utcOffset: [
      "+03:00"
    ],
    location: {
      lat: 9.145,
      lng: 40.489673
    }
  },

  {
    name: "Falkland Islands",
    dialCode: "500",
    iso2: "FK",
    iso3: "FLK",
    emoji: "🇫🇰",
    capital: "Stanley",
    timezone: [
      "Atlantic/Stanley"
    ],
    utcOffset: [
      "-03:00"
    ],
    location: {
      lat: -51.796253,
      lng: -59.523613
    }
  },

  {
    name: "Faroe Islands",
    dialCode: "298",
    iso2: "FO",
    iso3: "FRO",
    emoji: "🇫🇴",
    capital: "Torshavn",
    timezone: [
      "Atlantic/Faroe"
    ],
    utcOffset: [
      "+00:00"
    ],
    location: {
      lat: 61.892635,
      lng: -6.911806
    }
  },

  {
    name: "Fiji",
    dialCode: "679",
    iso2: "FJ",
    iso3: "FJI",
    emoji: "🇫🇯",
    capital: "Suva",
    timezone: [
      "Pacific/Fiji"
    ],
    utcOffset: [
      "+12:00"
    ],
    location: {
      lat: -16.578193,
      lng: 179.414413
    }
  },

  {
    name: "Finland",
    dialCode: "358",
    iso2: "FI",
    iso3: "FIN",
    emoji: "🇫🇮",
    capital: "Helsinki",
    timezone: [
      "Europe/Helsinki"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: 61.92411,
      lng: 25.748151
    }
  },

  {
    name: "France",
    dialCode: "33",
    iso2: "FR",
    iso3: "FRA",
    emoji: "🇫🇷",
    capital: "Paris",
    timezone: [
      "Europe/Paris"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 46.227638,
      lng: 2.213749
    }
  },

  {
    name: "French Guiana",
    dialCode: "594",
    iso2: "GF",
    iso3: "GUF",
    emoji: "🇬🇫",
    capital: "Cayenne",
    timezone: [
      "America/Cayenne"
    ],
    utcOffset: [
      "-03:00"
    ],
    location: {
      lat: 3.933889,
      lng: -53.125782
    }
  },

  {
    name: "French Polynesia",
    dialCode: "689",
    iso2: "PF",
    iso3: "PYF",
    emoji: "🇵🇫",
    capital: "Papeete",
    timezone: [
      "Pacific/Gambier",
      "Pacific/Marquesas",
      "Pacific/Tahiti"
    ],
    utcOffset: [
      "-09:00",
      "-09:30",
      "-10:00"
    ],
    location: {
      lat: -17.679742,
      lng: -149.406843
    }
  },

  {
    name: "French Southern Territories",
    dialCode: null,
    iso2: "TF",
    iso3: "ATF",
    emoji: "🇹🇫",
    capital: null,
    timezone: [
      "Indian/Kerguelen"
    ],
    utcOffset: [
      "+05:00"
    ],
    location: {
      lat: -49.280366,
      lng: 69.348557
    }
  },

  {
    name: "Gabon",
    dialCode: "241",
    iso2: "GA",
    iso3: "GAB",
    emoji: "🇬🇦",
    capital: "Libreville",
    timezone: [
      "Africa/Lagos"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: -0.803689,
      lng: 11.609444
    }
  },

  {
    name: "Gambia",
    dialCode: "220",
    iso2: "GM",
    iso3: "GMB",
    emoji: "🇬🇲",
    capital: "Banjul",
    timezone: [
      "Africa/Abidjan"
    ],
    utcOffset: [
      "+00:00"
    ],
    location: {
      lat: 13.443182,
      lng: -15.310139
    }
  },

  {
    name: "Georgia",
    dialCode: "995",
    iso2: "GE",
    iso3: "GEO",
    emoji: "🇬🇪",
    capital: "Tbilisi",
    timezone: [
      "Asia/Tbilisi"
    ],
    utcOffset: [
      "+04:00"
    ],
    location: {
      lat: 42.315407,
      lng: 43.356892
    }
  },

  {
    name: "Germany",
    dialCode: "49",
    iso2: "DE",
    iso3: "DEU",
    emoji: "🇩🇪",
    capital: "Berlin",
    timezone: [
      "Europe/Berlin",
      "Europe/Zurich"
    ],
    utcOffset: [
      "+01:00",
      "+01:00"
    ],
    location: {
      lat: 51.165691,
      lng: 10.451526
    }
  },

  {
    name: "Ghana",
    dialCode: "233",
    iso2: "GH",
    iso3: "GHA",
    emoji: "🇬🇭",
    capital: "Accra",
    timezone: [
      "Africa/Abidjan"
    ],
    utcOffset: [
      "+00:00"
    ],
    location: {
      lat: 7.946527,
      lng: -1.023194
    }
  },

  {
    name: "Gibraltar",
    dialCode: "350",
    iso2: "GI",
    iso3: "GIB",
    emoji: "🇬🇮",
    capital: "Gibraltar",
    timezone: [
      "Europe/Gibraltar"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 36.137741,
      lng: -5.345374
    }
  },

  {
    name: "Greece",
    dialCode: "30",
    iso2: "GR",
    iso3: "GRC",
    emoji: "🇬🇷",
    capital: "Athens",
    timezone: [
      "Europe/Athens"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: 39.074208,
      lng: 21.824312
    }
  },

  {
    name: "Greenland",
    dialCode: "299",
    iso2: "GL",
    iso3: "GRL",
    emoji: "🇬🇱",
    capital: "Nuuk",
    timezone: [
      "America/Danmarkshavn",
      "America/Nuuk",
      "America/Scoresbysund",
      "America/Thule"
    ],
    utcOffset: [
      "+00:00",
      "-03:00",
      "-01:00",
      "-04:00"
    ],
    location: {
      lat: 71.706936,
      lng: -42.604303
    }
  },

  {
    name: "Grenada",
    dialCode: "1473",
    iso2: "GD",
    iso3: "GRD",
    emoji: "🇬🇩",
    capital: "St. George's",
    timezone: [
      "America/Puerto_Rico"
    ],
    utcOffset: [
      "-04:00"
    ],
    location: {
      lat: 12.262776,
      lng: -61.604171
    }
  },

  {
    name: "Guadeloupe",
    dialCode: "590",
    iso2: "GP",
    iso3: "GLP",
    emoji: "🇬🇵",
    capital: "Basse-Terre",
    timezone: [
      "America/Puerto_Rico"
    ],
    utcOffset: [
      "-04:00"
    ],
    location: {
      lat: 16.995971,
      lng: -62.067641
    }
  },

  {
    name: "Guam",
    dialCode: "1671",
    iso2: "GU",
    iso3: "GUM",
    emoji: "🇬🇺",
    capital: "Hagatna",
    timezone: [
      "Pacific/Guam"
    ],
    utcOffset: [
      "+10:00"
    ],
    location: {
      lat: 13.444304,
      lng: 144.793731
    }
  },

  {
    name: "Guatemala",
    dialCode: "502",
    iso2: "GT",
    iso3: "GTM",
    emoji: "🇬🇹",
    capital: "Guatemala City",
    timezone: [
      "America/Guatemala"
    ],
    utcOffset: [
      "-06:00"
    ],
    location: {
      lat: 15.783471,
      lng: -90.230759
    }
  },

  {
    name: "Guernsey",
    dialCode: "44",
    iso2: "GG",
    iso3: "GGY",
    emoji: "🇬🇬",
    capital: "St Peter Port",
    timezone: [
      "Europe/London"
    ],
    utcOffset: [
      "+00:00"
    ],
    location: {
      lat: 49.465691,
      lng: -2.585278
    }
  },

  {
    name: "Guinea",
    dialCode: "224",
    iso2: "GN",
    iso3: "GIN",
    emoji: "🇬🇳",
    capital: "Conakry",
    timezone: [
      "Africa/Abidjan"
    ],
    utcOffset: [
      "+00:00"
    ],
    location: {
      lat: 9.945587,
      lng: -9.696645
    }
  },

  {
    name: "Guinea-Bissau",
    dialCode: "245",
    iso2: "GW",
    iso3: "GNB",
    emoji: "🇬🇼",
    capital: "Bissau",
    timezone: [
      "Africa/Bissau"
    ],
    utcOffset: [
      "+00:00"
    ],
    location: {
      lat: 11.803749,
      lng: -15.180413
    }
  },

  {
    name: "Guyana",
    dialCode: "595",
    iso2: "GY",
    iso3: "GUY",
    emoji: "🇬🇾",
    capital: "Georgetown",
    timezone: [
      "America/Guyana"
    ],
    utcOffset: [
      "-04:00"
    ],
    location: {
      lat: 4.860416,
      lng: -58.93018
    }
  },

  {
    name: "Haiti",
    dialCode: "509",
    iso2: "HT",
    iso3: "HTI",
    emoji: "🇭🇹",
    capital: "Port-au-Prince",
    timezone: [
      "America/Port-au-Prince"
    ],
    utcOffset: [
      "-05:00"
    ],
    location: {
      lat: 18.971187,
      lng: -72.285215
    }
  },

  {
    name: "Vatican City",
    dialCode: "379",
    iso2: "VA",
    iso3: "VAT",
    emoji: "🇻🇦",
    capital: "Vatican City",
    timezone: [
      "Europe/Rome"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 41.902916,
      lng: 12.453389
    }
  },

  {
    name: "Heard and McDonald Islands",
    dialCode: null,
    iso2: "HM",
    iso3: "HMD",
    emoji: "🇭🇲",
    capital: null,
    timezone: [
      "Indian/Kerguelen"
    ],
    utcOffset: [
      "+05:00"
    ],
    location: {
      lat: -53.08181,
      lng: 73.504158
    }
  },

  {
    name: "Honduras",
    dialCode: "504",
    iso2: "HN",
    iso3: "HND",
    emoji: "🇭🇳",
    capital: "Tegucigalpa",
    timezone: [
      "America/Tegucigalpa"
    ],
    utcOffset: [
      "-06:00"
    ],
    location: {
      lat: 15.199999,
      lng: -86.241905
    }
  },

  {
    name: "Hong Kong",
    dialCode: "852",
    iso2: "HK",
    iso3: "HKG",
    emoji: "🇭🇰",
    capital: "Hong Kong",
    timezone: [
      "Asia/Hong_Kong"
    ],
    utcOffset: [
      "+08:00"
    ],
    location: {
      lat: 22.396428,
      lng: 114.109497
    }
  },

  {
    name: "Hungary",
    dialCode: "36",
    iso2: "HU",
    iso3: "HUN",
    emoji: "🇭🇺",
    capital: "Budapest",
    timezone: [
      "Europe/Budapest"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 47.162494,
      lng: 19.503304
    }
  },

  {
    name: "Iceland",
    dialCode: "354",
    iso2: "IS",
    iso3: "ISL",
    emoji: "🇮🇸",
    capital: "Reykjavik",
    timezone: [
      "Atlantic/Reykjavik"
    ],
    utcOffset: [
      "+00:00"
    ],
    location: {
      lat: 64.963051,
      lng: -19.020835
    }
  },

  {
    name: "India",
    dialCode: "91",
    iso2: "IN",
    iso3: "IND",
    emoji: "🇮🇳",
    capital: "New Delhi",
    timezone: [
      "Asia/Kolkata"
    ],
    utcOffset: [
      "+05:30"
    ],
    location: {
      lat: 20.593684,
      lng: 78.96288
    }
  },

  {
    name: "Indonesia",
    dialCode: "62",
    iso2: "ID",
    iso3: "IDN",
    emoji: "🇮🇩",
    capital: "Jakarta",
    timezone: [
      "Asia/Jakarta",
      "Asia/Jayapura",
      "Asia/Makassar",
      "Asia/Pontianak"
    ],
    utcOffset: [
      "+07:00",
      "+09:00",
      "+08:00",
      "+07:00"
    ],
    location: {
      lat: -0.789275,
      lng: 113.921327
    }
  },

  {
    name: "Iran",
    dialCode: "98",
    iso2: "IR",
    iso3: "IRN",
    emoji: "🇮🇷",
    capital: "Tehran",
    timezone: [
      "Asia/Tehran"
    ],
    utcOffset: [
      "+03:30"
    ],
    location: {
      lat: 32.427908,
      lng: 53.688046
    }
  },

  {
    name: "Iraq",
    dialCode: "964",
    iso2: "IQ",
    iso3: "IRQ",
    emoji: "🇮🇶",
    capital: "Baghdad",
    timezone: [
      "Asia/Baghdad"
    ],
    utcOffset: [
      "+03:00"
    ],
    location: {
      lat: 33.223191,
      lng: 43.679291
    }
  },

  {
    name: "Ireland",
    dialCode: "353",
    iso2: "IE",
    iso3: "IRL",
    emoji: "🇮🇪",
    capital: "Dublin",
    timezone: [
      "Europe/Dublin"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 53.41291,
      lng: -8.24389
    }
  },

  {
    name: "Isle of Man",
    dialCode: "44",
    iso2: "IM",
    iso3: "IMN",
    emoji: "🇮🇲",
    capital: "Douglas, Isle of Man",
    timezone: [
      "Europe/London"
    ],
    utcOffset: [
      "+00:00"
    ],
    location: {
      lat: 54.236107,
      lng: -4.548056
    }
  },

  {
    name: "Israel",
    dialCode: "972",
    iso2: "IL",
    iso3: "ISR",
    emoji: "🇮🇱",
    capital: "Jerusalem",
    timezone: [
      "Asia/Jerusalem"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: 31.046051,
      lng: 34.851612
    }
  },

  {
    name: "Italy",
    dialCode: "39",
    iso2: "IT",
    iso3: "ITA",
    emoji: "🇮🇹",
    capital: "Rome",
    timezone: [
      "Europe/Rome"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 41.87194,
      lng: 12.56738
    }
  },

  {
    name: "Jamaica",
    dialCode: "1876",
    iso2: "JM",
    iso3: "JAM",
    emoji: "🇯🇲",
    capital: "Kingston",
    timezone: [
      "America/Jamaica"
    ],
    utcOffset: [
      "-05:00"
    ],
    location: {
      lat: 18.109581,
      lng: -77.297508
    }
  },

  {
    name: "Japan",
    dialCode: "81",
    iso2: "JP",
    iso3: "JPN",
    emoji: "🇯🇵",
    capital: "Tokyo",
    timezone: [
      "Asia/Tokyo"
    ],
    utcOffset: [
      "+09:00"
    ],
    location: {
      lat: 36.204824,
      lng: 138.252924
    }
  },

  {
    name: "Jersey",
    dialCode: "44",
    iso2: "JE",
    iso3: "JEY",
    emoji: "🇯🇪",
    capital: "Saint Helier",
    timezone: [
      "Europe/London"
    ],
    utcOffset: [
      "+00:00"
    ],
    location: {
      lat: 49.214439,
      lng: -2.13125
    }
  },

  {
    name: "Jordan",
    dialCode: "962",
    iso2: "JO",
    iso3: "JOR",
    emoji: "🇯🇴",
    capital: "Amman",
    timezone: [
      "Asia/Amman"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: 30.585164,
      lng: 36.238414
    }
  },

  {
    name: "Kazakhstan",
    dialCode: "77",
    iso2: "KZ",
    iso3: "KAZ",
    emoji: "🇰🇿",
    capital: "Astana",
    timezone: [
      "Asia/Almaty",
      "Asia/Aqtau",
      "Asia/Aqtobe",
      "Asia/Atyrau",
      "Asia/Oral",
      "Asia/Qostanay",
      "Asia/Qyzylorda"
    ],
    utcOffset: [
      "+06:00",
      "+05:00",
      "+05:00",
      "+05:00",
      "+05:00",
      "+06:00",
      "+05:00"
    ],
    location: {
      lat: 48.019573,
      lng: 66.923684
    }
  },

  {
    name: "Kenya",
    dialCode: "254",
    iso2: "KE",
    iso3: "KEN",
    emoji: "🇰🇪",
    capital: "Nairobi",
    timezone: [
      "Africa/Nairobi"
    ],
    utcOffset: [
      "+03:00"
    ],
    location: {
      lat: -0.023559,
      lng: 37.906193
    }
  },

  {
    name: "Kiribati",
    dialCode: "686",
    iso2: "KI",
    iso3: "KIR",
    emoji: "🇰🇮",
    capital: "Tarawa",
    timezone: [
      "Pacific/Kanton",
      "Pacific/Kiritimati",
      "Pacific/Tarawa"
    ],
    utcOffset: [
      "+13:00",
      "+14:00",
      "+12:00"
    ],
    location: {
      lat: -3.370417,
      lng: -168.734039
    }
  },

  {
    name: "Kosovo",
    dialCode: null,
    iso2: "XK",
    iso3: "XXK",
    emoji: "🇽🇰",
    capital: "Pristina",
    timezone: [
      "Europe/Belgrade"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 42.602636,
      lng: 20.902977
    }
  },

  {
    name: "North Korea",
    dialCode: "850",
    iso2: "KP",
    iso3: "PRK",
    emoji: "🇰🇵",
    capital: "Pyongyang",
    timezone: [
      "Asia/Pyongyang"
    ],
    utcOffset: [
      "+09:00"
    ],
    location: {
      lat: 40.339852,
      lng: 127.510093
    }
  },

  {
    name: "South Korea",
    dialCode: "82",
    iso2: "KR",
    iso3: "KOR",
    emoji: "🇰🇷",
    capital: "Seoul",
    timezone: [
      "Asia/Seoul"
    ],
    utcOffset: [
      "+09:00"
    ],
    location: {
      lat: 35.907757,
      lng: 127.766922
    }
  },

  {
    name: "Kuwait",
    dialCode: "965",
    iso2: "KW",
    iso3: "KWT",
    emoji: "🇰🇼",
    capital: "Kuwait City",
    timezone: [
      "Asia/Riyadh"
    ],
    utcOffset: [
      "+03:00"
    ],
    location: {
      lat: 29.31166,
      lng: 47.481766
    }
  },

  {
    name: "Kyrgyzstan",
    dialCode: "996",
    iso2: "KG",
    iso3: "KGZ",
    emoji: "🇰🇬",
    capital: "Bishkek",
    timezone: [
      "Asia/Bishkek"
    ],
    utcOffset: [
      "+06:00"
    ],
    location: {
      lat: 41.20438,
      lng: 74.766098
    }
  },

  {
    name: "Laos",
    dialCode: "856",
    iso2: "LA",
    iso3: "LAO",
    emoji: "🇱🇦",
    capital: "Vientiane",
    timezone: [
      "Asia/Bangkok"
    ],
    utcOffset: [
      "+07:00"
    ],
    location: {
      lat: 19.85627,
      lng: 102.495496
    }
  },

  {
    name: "Latvia",
    dialCode: "371",
    iso2: "LV",
    iso3: "LVA",
    emoji: "🇱🇻",
    capital: "Riga",
    timezone: [
      "Europe/Riga"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: 56.879635,
      lng: 24.603189
    }
  },

  {
    name: "Lebanon",
    dialCode: "961",
    iso2: "LB",
    iso3: "LBN",
    emoji: "🇱🇧",
    capital: "Beirut",
    timezone: [
      "Asia/Beirut"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: 33.854721,
      lng: 35.862285
    }
  },

  {
    name: "Lesotho",
    dialCode: "266",
    iso2: "LS",
    iso3: "LSO",
    emoji: "🇱🇸",
    capital: "Maseru",
    timezone: [
      "Africa/Johannesburg"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: -29.609988,
      lng: 28.233608
    }
  },

  {
    name: "Liberia",
    dialCode: "231",
    iso2: "LR",
    iso3: "LBR",
    emoji: "🇱🇷",
    capital: "Monrovia",
    timezone: [
      "Africa/Monrovia"
    ],
    utcOffset: [
      "+00:00"
    ],
    location: {
      lat: 6.428055,
      lng: -9.429499
    }
  },

  {
    name: "Libya",
    dialCode: "218",
    iso2: "LY",
    iso3: "LBY",
    emoji: "🇱🇾",
    capital: "Tripolis",
    timezone: [
      "Africa/Tripoli"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: 26.3351,
      lng: 17.228331
    }
  },

  {
    name: "Liechtenstein",
    dialCode: "423",
    iso2: "LI",
    iso3: "LIE",
    emoji: "🇱🇮",
    capital: "Vaduz",
    timezone: [
      "Europe/Zurich"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 47.166,
      lng: 9.555373
    }
  },

  {
    name: "Lithuania",
    dialCode: "370",
    iso2: "LT",
    iso3: "LTU",
    emoji: "🇱🇹",
    capital: "Vilnius",
    timezone: [
      "Europe/Vilnius"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: 55.169438,
      lng: 23.881275
    }
  },

  {
    name: "Luxembourg",
    dialCode: "352",
    iso2: "LU",
    iso3: "LUX",
    emoji: "🇱🇺",
    capital: "Luxembourg",
    timezone: [
      "Europe/Luxembourg"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 49.815273,
      lng: 6.129583
    }
  },

  {
    name: "Macau",
    dialCode: "853",
    iso2: "MO",
    iso3: "MAC",
    emoji: "🇲🇴",
    capital: "Macao",
    timezone: [
      "Asia/Macau"
    ],
    utcOffset: [
      "+08:00"
    ],
    location: {
      lat: 22.198745,
      lng: 113.543873
    }
  },

  {
    name: "North Macedonia",
    dialCode: "389",
    iso2: "MK",
    iso3: "MKD",
    emoji: "🇲🇰",
    capital: "Skopje",
    timezone: [
      "Europe/Belgrade"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 41.608635,
      lng: 21.745275
    }
  },

  {
    name: "Madagascar",
    dialCode: "261",
    iso2: "MG",
    iso3: "MDG",
    emoji: "🇲🇬",
    capital: "Antananarivo",
    timezone: [
      "Africa/Nairobi"
    ],
    utcOffset: [
      "+03:00"
    ],
    location: {
      lat: -18.766947,
      lng: 46.869107
    }
  },

  {
    name: "Malawi",
    dialCode: "265",
    iso2: "MW",
    iso3: "MWI",
    emoji: "🇲🇼",
    capital: "Lilongwe",
    timezone: [
      "Africa/Maputo"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: -13.254308,
      lng: 34.301525
    }
  },

  {
    name: "Malaysia",
    dialCode: "60",
    iso2: "MY",
    iso3: "MYS",
    emoji: "🇲🇾",
    capital: "Kuala Lumpur",
    timezone: [
      "Asia/Kuala_Lumpur",
      "Asia/Kuching",
      "Asia/Singapore"
    ],
    utcOffset: [
      "+08:00",
      "+08:00",
      "+08:00"
    ],
    location: {
      lat: 4.210484,
      lng: 101.975766
    }
  },

  {
    name: "Maldives",
    dialCode: "960",
    iso2: "MV",
    iso3: "MDV",
    emoji: "🇲🇻",
    capital: "Male",
    timezone: [
      "Indian/Maldives"
    ],
    utcOffset: [
      "+05:00"
    ],
    location: {
      lat: 3.202778,
      lng: 73.22068
    }
  },

  {
    name: "Mali",
    dialCode: "223",
    iso2: "ML",
    iso3: "MLI",
    emoji: "🇲🇱",
    capital: "Bamako",
    timezone: [
      "Africa/Abidjan"
    ],
    utcOffset: [
      "+00:00"
    ],
    location: {
      lat: 17.570692,
      lng: -3.996166
    }
  },

  {
    name: "Malta",
    dialCode: "356",
    iso2: "MT",
    iso3: "MLT",
    emoji: "🇲🇹",
    capital: "Valletta",
    timezone: [
      "Europe/Malta"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 35.937496,
      lng: 14.375416
    }
  },

  {
    name: "Marshall Islands",
    dialCode: "692",
    iso2: "MH",
    iso3: "MHL",
    emoji: "🇲🇭",
    capital: "Majuro",
    timezone: [
      "Pacific/Kwajalein",
      "Pacific/Majuro"
    ],
    utcOffset: [
      "+12:00",
      "+12:00"
    ],
    location: {
      lat: 7.131474,
      lng: 171.184478
    }
  },

  {
    name: "Martinique",
    dialCode: "596",
    iso2: "MQ",
    iso3: "MTQ",
    emoji: "🇲🇶",
    capital: "Fort-de-France",
    timezone: [
      "America/Martinique"
    ],
    utcOffset: [
      "-04:00"
    ],
    location: {
      lat: 14.641528,
      lng: -61.024174
    }
  },

  {
    name: "Mauritania",
    dialCode: "222",
    iso2: "MR",
    iso3: "MRT",
    emoji: "🇲🇷",
    capital: "Nouakchott",
    timezone: [
      "Africa/Abidjan"
    ],
    utcOffset: [
      "+00:00"
    ],
    location: {
      lat: 21.00789,
      lng: -10.940835
    }
  },

  {
    name: "Mauritius",
    dialCode: "230",
    iso2: "MU",
    iso3: "MUS",
    emoji: "🇲🇺",
    capital: "Port Louis",
    timezone: [
      "Indian/Mauritius"
    ],
    utcOffset: [
      "+04:00"
    ],
    location: {
      lat: -20.348404,
      lng: 57.552152
    }
  },

  {
    name: "Mayotte",
    dialCode: "262",
    iso2: "YT",
    iso3: "MYT",
    emoji: "🇾🇹",
    capital: "Mamoudzou",
    timezone: [
      "Africa/Nairobi"
    ],
    utcOffset: [
      "+03:00"
    ],
    location: {
      lat: -12.8275,
      lng: 45.166244
    }
  },

  {
    name: "Mexico",
    dialCode: "52",
    iso2: "MX",
    iso3: "MEX",
    emoji: "🇲🇽",
    capital: "Mexico City",
    timezone: [
      "America/Bahia_Banderas",
      "America/Cancun",
      "America/Chihuahua",
      "America/Hermosillo",
      "America/Matamoros",
      "America/Mazatlan",
      "America/Merida",
      "America/Mexico_City",
      "America/Monterrey",
      "America/Ojinaga",
      "America/Tijuana"
    ],
    utcOffset: [
      "-06:00",
      "-05:00",
      "-07:00",
      "-07:00",
      "-06:00",
      "-07:00",
      "-06:00",
      "-06:00",
      "-06:00",
      "-07:00",
      "-08:00"
    ],
    location: {
      lat: 23.634501,
      lng: -102.552784
    }
  },

  {
    name: "Micronesia",
    dialCode: "691",
    iso2: "FM",
    iso3: "FSM",
    emoji: "🇫🇲",
    capital: "Palikir",
    timezone: [
      "Pacific/Chuuk",
      "Pacific/Kosrae",
      "Pacific/Pohnpei"
    ],
    utcOffset: [
      "+10:00",
      "+11:00",
      "+11:00"
    ],
    location: {
      lat: 7.425554,
      lng: 150.550812
    }
  },

  {
    name: "Moldova",
    dialCode: "373",
    iso2: "MD",
    iso3: "MDA",
    emoji: "🇲🇩",
    capital: "Chisinau",
    timezone: [
      "Europe/Chisinau"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: 47.411631,
      lng: 28.369885
    }
  },

  {
    name: "Monaco",
    dialCode: "377",
    iso2: "MC",
    iso3: "MCO",
    emoji: "🇲🇨",
    capital: "Monaco",
    timezone: [
      "Europe/Monaco"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 43.750298,
      lng: 7.412841
    }
  },

  {
    name: "Mongolia",
    dialCode: "976",
    iso2: "MN",
    iso3: "MNG",
    emoji: "🇲🇳",
    capital: "Ulan Bator",
    timezone: [
      "Asia/Choibalsan",
      "Asia/Hovd",
      "Asia/Ulaanbaatar"
    ],
    utcOffset: [
      "+08:00",
      "+07:00",
      "+08:00"
    ],
    location: {
      lat: 46.862496,
      lng: 103.846656
    }
  },

  {
    name: "Montenegro",
    dialCode: "382",
    iso2: "ME",
    iso3: "MNE",
    emoji: "🇲🇪",
    capital: "Podgorica",
    timezone: [
      "Europe/Belgrade"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 42.708678,
      lng: 19.37439
    }
  },

  {
    name: "Montserrat",
    dialCode: "1664",
    iso2: "MS",
    iso3: "MSR",
    emoji: "🇲🇸",
    capital: "Plymouth",
    timezone: [
      "America/Puerto_Rico"
    ],
    utcOffset: [
      "-04:00"
    ],
    location: {
      lat: 16.742498,
      lng: -62.187366
    }
  },

  {
    name: "Morocco",
    dialCode: "212",
    iso2: "MA",
    iso3: "MAR",
    emoji: "🇲🇦",
    capital: "Rabat",
    timezone: [
      "Africa/Casablanca"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 31.791702,
      lng: -7.09262
    }
  },

  {
    name: "Mozambique",
    dialCode: "258",
    iso2: "MZ",
    iso3: "MOZ",
    emoji: "🇲🇿",
    capital: "Maputo",
    timezone: [
      "Africa/Maputo"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: -18.665695,
      lng: 35.529562
    }
  },

  {
    name: "Myanmar",
    dialCode: "95",
    iso2: "MM",
    iso3: "MMR",
    emoji: "🇲🇲",
    capital: "Nay Pyi Taw",
    timezone: [
      "Asia/Yangon"
    ],
    utcOffset: [
      "+06:30"
    ],
    location: {
      lat: 21.913965,
      lng: 95.956223
    }
  },

  {
    name: "Namibia",
    dialCode: "264",
    iso2: "NA",
    iso3: "NAM",
    emoji: "🇳🇦",
    capital: "Windhoek",
    timezone: [
      "Africa/Windhoek"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: -22.95764,
      lng: 18.49041
    }
  },

  {
    name: "Nauru",
    dialCode: "674",
    iso2: "NR",
    iso3: "NRU",
    emoji: "🇳🇷",
    capital: "Yaren",
    timezone: [
      "Pacific/Nauru"
    ],
    utcOffset: [
      "+12:00"
    ],
    location: {
      lat: -0.522778,
      lng: 166.931503
    }
  },

  {
    name: "Nepal",
    dialCode: "977",
    iso2: "NP",
    iso3: "NPL",
    emoji: "🇳🇵",
    capital: "Kathmandu",
    timezone: [
      "Asia/Kathmandu"
    ],
    utcOffset: [
      "+05:45"
    ],
    location: {
      lat: 28.394857,
      lng: 84.124008
    }
  },

  {
    name: "Netherlands",
    dialCode: "31",
    iso2: "NL",
    iso3: "NLD",
    emoji: "🇳🇱",
    capital: "Amsterdam",
    timezone: [
      "Europe/Amsterdam"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 52.132633,
      lng: 5.291266
    }
  },

  {
    name: "Netherlands Antilles",
    dialCode: null,
    iso2: "AN",
    iso3: "ANT",
    emoji: "🇳🇱",
    capital: null,
    timezone: [
      "America/Curacao"
    ],
    utcOffset: [
      "-04:00"
    ],
    location: {
      lat: 12.226079,
      lng: -69.060087
    }
  },

  {
    name: "New Caledonia",
    dialCode: "687",
    iso2: "NC",
    iso3: "NCL",
    emoji: "🇳🇨",
    capital: "Noumea",
    timezone: [
      "Pacific/Noumea"
    ],
    utcOffset: [
      "+11:00"
    ],
    location: {
      lat: -20.904305,
      lng: 165.618042
    }
  },

  {
    name: "New Zealand",
    dialCode: "64",
    iso2: "NZ",
    iso3: "NZL",
    emoji: "🇳🇿",
    capital: "Wellington",
    timezone: [
      "Pacific/Auckland",
      "Pacific/Chatham"
    ],
    utcOffset: [
      "+12:00",
      "+12:45"
    ],
    location: {
      lat: -40.900557,
      lng: 174.885971
    }
  },

  {
    name: "Nicaragua",
    dialCode: "505",
    iso2: "NI",
    iso3: "NIC",
    emoji: "🇳🇮",
    capital: "Managua",
    timezone: [
      "America/Managua"
    ],
    utcOffset: [
      "-06:00"
    ],
    location: {
      lat: 12.865416,
      lng: -85.207229
    }
  },

  {
    name: "Niger",
    dialCode: "227",
    iso2: "NE",
    iso3: "NER",
    emoji: "🇳🇪",
    capital: "Niamey",
    timezone: [
      "Africa/Lagos"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 17.607789,
      lng: 8.081666
    }
  },

  {
    name: "Nigeria",
    dialCode: "234",
    iso2: "NG",
    iso3: "NGA",
    emoji: "🇳🇬",
    capital: "Abuja",
    timezone: [
      "Africa/Lagos"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 9.081999,
      lng: 8.675277
    }
  },

  {
    name: "Niue",
    dialCode: "683",
    iso2: "NU",
    iso3: "NIU",
    emoji: "🇳🇺",
    capital: "Alofi",
    timezone: [
      "Pacific/Niue"
    ],
    utcOffset: [
      "-11:00"
    ],
    location: {
      lat: -19.054445,
      lng: -169.867233
    }
  },

  {
    name: "Norfolk Island",
    dialCode: "672",
    iso2: "NF",
    iso3: "NFK",
    emoji: "🇳🇫",
    capital: "Kingston",
    timezone: [
      "Pacific/Norfolk"
    ],
    utcOffset: [
      "+11:00"
    ],
    location: {
      lat: -29.040835,
      lng: 167.954712
    }
  },

  {
    name: "Northern Mariana Islands",
    dialCode: "1670",
    iso2: "MP",
    iso3: "MNP",
    emoji: "🇲🇵",
    capital: "Saipan",
    timezone: [
      "Pacific/Guam"
    ],
    utcOffset: [
      "+10:00"
    ],
    location: {
      lat: 17.33083,
      lng: 145.38469
    }
  },

  {
    name: "Norway",
    dialCode: "47",
    iso2: "NO",
    iso3: "NOR",
    emoji: "🇳🇴",
    capital: "Oslo",
    timezone: [
      "Europe/Oslo"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 60.472024,
      lng: 8.468946
    }
  },

  {
    name: "Oman",
    dialCode: "968",
    iso2: "OM",
    iso3: "OMN",
    emoji: "🇴🇲",
    capital: "Muscat",
    timezone: [
      "Asia/Dubai"
    ],
    utcOffset: [
      "+04:00"
    ],
    location: {
      lat: 21.512583,
      lng: 55.923255
    }
  },

  {
    name: "Pakistan",
    dialCode: "92",
    iso2: "PK",
    iso3: "PAK",
    emoji: "🇵🇰",
    capital: "Islamabad",
    timezone: [
      "Asia/Karachi"
    ],
    utcOffset: [
      "+05:00"
    ],
    location: {
      lat: 30.375321,
      lng: 69.345116
    }
  },

  {
    name: "Palau",
    dialCode: "680",
    iso2: "PW",
    iso3: "PLW",
    emoji: "🇵🇼",
    capital: "Melekeok",
    timezone: [
      "Pacific/Palau"
    ],
    utcOffset: [
      "+09:00"
    ],
    location: {
      lat: 7.51498,
      lng: 134.58252
    }
  },

  {
    name: "Palestine",
    dialCode: "970",
    iso2: "PS",
    iso3: "PSE",
    emoji: "🇵🇸",
    capital: "East Jerusalem",
    timezone: [
      "Asia/Gaza",
      "Asia/Hebron"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: 31.952162,
      lng: 35.233154
    }
  },

  {
    name: "Panama",
    dialCode: "507",
    iso2: "PA",
    iso3: "PAN",
    emoji: "🇵🇦",
    capital: "Panama City",
    timezone: [
      "America/Panama"
    ],
    utcOffset: [
      "-05:00"
    ],
    location: {
      lat: 8.537981,
      lng: -80.782127
    }
  },

  {
    name: "Papua New Guinea",
    dialCode: "675",
    iso2: "PG",
    iso3: "PNG",
    emoji: "🇵🇬",
    capital: "Port Moresby",
    timezone: [
      "Pacific/Bougainville",
      "Pacific/Port_Moresby"
    ],
    utcOffset: [
      "+11:00",
      "+10:00"
    ],
    location: {
      lat: -6.314993,
      lng: 143.95555
    }
  },

  {
    name: "Paraguay",
    dialCode: "595",
    iso2: "PY",
    iso3: "PRY",
    emoji: "🇵🇾",
    capital: "Asuncion",
    timezone: [
      "America/Asuncion"
    ],
    utcOffset: [
      "-04:00"
    ],
    location: {
      lat: -23.442503,
      lng: -58.443832
    }
  },

  {
    name: "Peru",
    dialCode: "51",
    iso2: "PE",
    iso3: "PER",
    emoji: "🇵🇪",
    capital: "Lima",
    timezone: [
      "America/Lima"
    ],
    utcOffset: [
      "-05:00"
    ],
    location: {
      lat: -9.189967,
      lng: -75.015152
    }
  },

  {
    name: "Philippines",
    dialCode: "63",
    iso2: "PH",
    iso3: "PHL",
    emoji: "🇵🇭",
    capital: "Manila",
    timezone: [
      "Asia/Manila"
    ],
    utcOffset: [
      "+08:00"
    ],
    location: {
      lat: 12.879721,
      lng: 121.774017
    }
  },

  {
    name: "Pitcairn Islands",
    dialCode: "872",
    iso2: "PN",
    iso3: "PCN",
    emoji: "🇵🇳",
    capital: "Adamstown",
    timezone: [
      "Pacific/Pitcairn"
    ],
    utcOffset: [
      "-08:00"
    ],
    location: {
      lat: -24.703615,
      lng: -127.439308
    }
  },

  {
    name: "Poland",
    dialCode: "48",
    iso2: "PL",
    iso3: "POL",
    emoji: "🇵🇱",
    capital: "Warsaw",
    timezone: [
      "Europe/Warsaw"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 51.919438,
      lng: 19.145136
    }
  },

  {
    name: "Portugal",
    dialCode: "351",
    iso2: "PT",
    iso3: "PRT",
    emoji: "🇵🇹",
    capital: "Lisbon",
    timezone: [
      "Atlantic/Azores",
      "Atlantic/Madeira",
      "Europe/Lisbon"
    ],
    utcOffset: [
      "-01:00",
      "+00:00",
      "+00:00"
    ],
    location: {
      lat: 39.399872,
      lng: -8.224454
    }
  },

  {
    name: "Puerto Rico",
    dialCode: "1939",
    iso2: "PR",
    iso3: "PRI",
    emoji: "🇵🇷",
    capital: "San Juan",
    timezone: [
      "America/Puerto_Rico"
    ],
    utcOffset: [
      "-04:00"
    ],
    location: {
      lat: 18.220833,
      lng: -66.590149
    }
  },

  {
    name: "Qatar",
    dialCode: "974",
    iso2: "QA",
    iso3: "QAT",
    emoji: "🇶🇦",
    capital: "Doha",
    timezone: [
      "Asia/Qatar"
    ],
    utcOffset: [
      "+03:00"
    ],
    location: {
      lat: 25.354826,
      lng: 51.183884
    }
  },

  {
    name: "Romania",
    dialCode: "40",
    iso2: "RO",
    iso3: "ROU",
    emoji: "🇷🇴",
    capital: "Bucharest",
    timezone: [
      "Europe/Bucharest"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: 45.943161,
      lng: 24.96676
    }
  },

  {
    name: "Russia",
    dialCode: "7",
    iso2: "RU",
    iso3: "RUS",
    emoji: "🇷🇺",
    capital: "Moscow",
    timezone: [
      "Asia/Anadyr",
      "Asia/Barnaul",
      "Asia/Chita",
      "Asia/Irkutsk",
      "Asia/Kamchatka",
      "Asia/Khandyga",
      "Asia/Krasnoyarsk",
      "Asia/Magadan",
      "Asia/Novokuznetsk",
      "Asia/Novosibirsk",
      "Asia/Omsk",
      "Asia/Sakhalin",
      "Asia/Srednekolymsk",
      "Asia/Tomsk",
      "Asia/Ust-Nera",
      "Asia/Vladivostok",
      "Asia/Yakutsk",
      "Asia/Yekaterinburg",
      "Europe/Astrakhan",
      "Europe/Kaliningrad",
      "Europe/Kirov",
      "Europe/Moscow",
      "Europe/Samara",
      "Europe/Saratov",
      "Europe/Simferopol",
      "Europe/Ulyanovsk",
      "Europe/Volgograd"
    ],
    utcOffset: [
      "+12:00",
      "+07:00",
      "+09:00",
      "+08:00",
      "+12:00",
      "+09:00",
      "+07:00",
      "+11:00",
      "+07:00",
      "+07:00",
      "+06:00",
      "+11:00",
      "+11:00",
      "+07:00",
      "+10:00",
      "+10:00",
      "+09:00",
      "+05:00",
      "+04:00",
      "+02:00",
      "+03:00",
      "+03:00",
      "+04:00",
      "+04:00",
      "+03:00",
      "+04:00",
      "+03:00"
    ],
    location: {
      lat: 61.52401,
      lng: 105.318756
    }
  },

  {
    name: "Rwanda",
    dialCode: "250",
    iso2: "RW",
    iso3: "RWA",
    emoji: "🇷🇼",
    capital: "Kigali",
    timezone: [
      "Africa/Maputo"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: -1.940278,
      lng: 29.873888
    }
  },

  {
    name: "Réunion",
    dialCode: "262",
    iso2: "RE",
    iso3: "REU",
    emoji: "🇷🇪",
    capital: "Saint-Denis",
    timezone: [
      "Indian/Reunion"
    ],
    utcOffset: [
      "+04:00"
    ],
    location: {
      lat: -21.115141,
      lng: 55.536384
    }
  },

  {
    name: "St. Barthélemy",
    dialCode: "590",
    iso2: "BL",
    iso3: "BLM",
    emoji: "🇧🇱",
    capital: "Gustavia",
    timezone: [
      "America/Puerto_Rico"
    ],
    utcOffset: [
      "-04:00"
    ],
    location: {
      lat: 17.900857,
      lng: -62.833862
    }
  },

  {
    name: "St. Helena",
    dialCode: "290",
    iso2: "SH",
    iso3: "SHN",
    emoji: "🇸🇭",
    capital: "Jamestown",
    timezone: [
      "Africa/Abidjan"
    ],
    utcOffset: [
      "+00:00"
    ],
    location: {
      lat: -24.143474,
      lng: -10.030696
    }
  },

  {
    name: "St. Kitts & Nevis",
    dialCode: "1869",
    iso2: "KN",
    iso3: "KNA",
    emoji: "🇰🇳",
    capital: "Basseterre",
    timezone: [
      "America/Puerto_Rico"
    ],
    utcOffset: [
      "-04:00"
    ],
    location: {
      lat: 17.357822,
      lng: -62.782998
    }
  },

  {
    name: "St. Lucia",
    dialCode: "1758",
    iso2: "LC",
    iso3: "LCA",
    emoji: "🇱🇨",
    capital: "Castries",
    timezone: [
      "America/Puerto_Rico"
    ],
    utcOffset: [
      "-04:00"
    ],
    location: {
      lat: 13.909444,
      lng: -60.978893
    }
  },

  {
    name: "St. Martin",
    dialCode: "590",
    iso2: "MF",
    iso3: "MAF",
    emoji: "🇲🇫",
    capital: "Marigot",
    timezone: [
      "America/Puerto_Rico"
    ],
    utcOffset: [
      "-04:00"
    ],
    location: {
      lat: 18.070829,
      lng: -63.050081
    }
  },

  {
    name: "St. Pierre & Miquelon",
    dialCode: "508",
    iso2: "PM",
    iso3: "SPM",
    emoji: "🇵🇲",
    capital: "Saint-Pierre",
    timezone: [
      "America/Miquelon"
    ],
    utcOffset: [
      "-03:00"
    ],
    location: {
      lat: 46.941936,
      lng: -56.27111
    }
  },

  {
    name: "St. Vincent & Grenadines",
    dialCode: "1784",
    iso2: "VC",
    iso3: "VCT",
    emoji: "🇻🇨",
    capital: "Kingstown",
    timezone: [
      "America/Puerto_Rico"
    ],
    utcOffset: [
      "-04:00"
    ],
    location: {
      lat: 12.984305,
      lng: -61.287228
    }
  },

  {
    name: "Samoa",
    dialCode: "685",
    iso2: "WS",
    iso3: "WSM",
    emoji: "🇼🇸",
    capital: "Apia",
    timezone: [
      "Pacific/Apia"
    ],
    utcOffset: [
      "+13:00"
    ],
    location: {
      lat: -13.759029,
      lng: -172.104629
    }
  },

  {
    name: "San Marino",
    dialCode: "378",
    iso2: "SM",
    iso3: "SMR",
    emoji: "🇸🇲",
    capital: "San Marino",
    timezone: [
      "Europe/Rome"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 43.94236,
      lng: 12.457777
    }
  },

  {
    name: "São Tomé & Príncipe",
    dialCode: "239",
    iso2: "ST",
    iso3: "STP",
    emoji: "🇸🇹",
    capital: "Sao Tome",
    timezone: [
      "Africa/Sao_Tome"
    ],
    utcOffset: [
      "+00:00"
    ],
    location: {
      lat: 0.18636,
      lng: 6.613081
    }
  },

  {
    name: "Saudi Arabia",
    dialCode: "966",
    iso2: "SA",
    iso3: "SAU",
    emoji: "🇸🇦",
    capital: "Riyadh",
    timezone: [
      "Asia/Riyadh"
    ],
    utcOffset: [
      "+03:00"
    ],
    location: {
      lat: 23.885942,
      lng: 45.079162
    }
  },

  {
    name: "Senegal",
    dialCode: "221",
    iso2: "SN",
    iso3: "SEN",
    emoji: "🇸🇳",
    capital: "Dakar",
    timezone: [
      "Africa/Abidjan"
    ],
    utcOffset: [
      "+00:00"
    ],
    location: {
      lat: 14.497401,
      lng: -14.452362
    }
  },

  {
    name: "Serbia",
    dialCode: "381",
    iso2: "RS",
    iso3: "SRB",
    emoji: "🇷🇸",
    capital: "Belgrade",
    timezone: [
      "Europe/Belgrade"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 44.016521,
      lng: 21.005859
    }
  },

  {
    name: "Seychelles",
    dialCode: "248",
    iso2: "SC",
    iso3: "SYC",
    emoji: "🇸🇨",
    capital: "Victoria",
    timezone: [
      "Indian/Mahe"
    ],
    utcOffset: [
      "+04:00"
    ],
    location: {
      lat: -4.679574,
      lng: 55.491977
    }
  },

  {
    name: "Sierra Leone",
    dialCode: "232",
    iso2: "SL",
    iso3: "SLE",
    emoji: "🇸🇱",
    capital: "Freetown",
    timezone: [
      "Africa/Abidjan"
    ],
    utcOffset: [
      "+00:00"
    ],
    location: {
      lat: 8.460555,
      lng: -11.779889
    }
  },

  {
    name: "Singapore",
    dialCode: "65",
    iso2: "SG",
    iso3: "SGP",
    emoji: "🇸🇬",
    capital: "Singapur",
    timezone: [
      "Asia/Singapore"
    ],
    utcOffset: [
      "+08:00"
    ],
    location: {
      lat: 1.352083,
      lng: 103.819836
    }
  },

  {
    name: "Slovakia",
    dialCode: "421",
    iso2: "SK",
    iso3: "SVK",
    emoji: "🇸🇰",
    capital: "Bratislava",
    timezone: [
      "Europe/Prague"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 48.669026,
      lng: 19.699024
    }
  },

  {
    name: "Slovenia",
    dialCode: "386",
    iso2: "SI",
    iso3: "SVN",
    emoji: "🇸🇮",
    capital: "Ljubljana",
    timezone: [
      "Europe/Belgrade"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 46.151241,
      lng: 14.995463
    }
  },

  {
    name: "Solomon Islands",
    dialCode: "677",
    iso2: "SB",
    iso3: "SLB",
    emoji: "🇸🇧",
    capital: "Honiara",
    timezone: [
      "Pacific/Guadalcanal"
    ],
    utcOffset: [
      "+11:00"
    ],
    location: {
      lat: -9.64571,
      lng: 160.156194
    }
  },

  {
    name: "Somalia",
    dialCode: "252",
    iso2: "SO",
    iso3: "SOM",
    emoji: "🇸🇴",
    capital: "Mogadishu",
    timezone: [
      "Africa/Nairobi"
    ],
    utcOffset: [
      "+03:00"
    ],
    location: {
      lat: 5.152149,
      lng: 46.199616
    }
  },

  {
    name: "South Africa",
    dialCode: "27",
    iso2: "ZA",
    iso3: "ZAF",
    emoji: "🇿🇦",
    capital: "Pretoria",
    timezone: [
      "Africa/Johannesburg"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: -30.559482,
      lng: 22.937506
    }
  },

  {
    name: "South Sudan",
    dialCode: "211",
    iso2: "SS",
    iso3: "SSD",
    emoji: "🇸🇸",
    capital: "Juba",
    timezone: [
      "Africa/Juba"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: 6.876991,
      lng: 31.306978
    }
  },

  {
    name: "South Georgia & South Sandwich Islands",
    dialCode: "500",
    iso2: "GS",
    iso3: "SGS",
    emoji: "🇬🇸",
    capital: "Grytviken",
    timezone: [
      "Atlantic/South_Georgia"
    ],
    utcOffset: [
      "-02:00"
    ],
    location: {
      lat: -54.429579,
      lng: -36.587909
    }
  },

  {
    name: "Spain",
    dialCode: "34",
    iso2: "ES",
    iso3: "ESP",
    emoji: "🇪🇸",
    capital: "Madrid",
    timezone: [
      "Africa/Ceuta",
      "Atlantic/Canary",
      "Europe/Madrid"
    ],
    utcOffset: [
      "+01:00",
      "+00:00",
      "+01:00"
    ],
    location: {
      lat: 40.463667,
      lng: -3.74922
    }
  },

  {
    name: "Sri Lanka",
    dialCode: "94",
    iso2: "LK",
    iso3: "LKA",
    emoji: "🇱🇰",
    capital: "Colombo",
    timezone: [
      "Asia/Colombo"
    ],
    utcOffset: [
      "+05:30"
    ],
    location: {
      lat: 7.873054,
      lng: 80.771797
    }
  },

  {
    name: "Sudan",
    dialCode: "249",
    iso2: "SD",
    iso3: "SDN",
    emoji: "🇸🇩",
    capital: "Khartoum",
    timezone: [
      "Africa/Khartoum"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: 12.862807,
      lng: 30.217636
    }
  },

  {
    name: "Suriname",
    dialCode: "597",
    iso2: "SR",
    iso3: "SUR",
    emoji: "🇸🇷",
    capital: "Paramaribo",
    timezone: [
      "America/Paramaribo"
    ],
    utcOffset: [
      "-03:00"
    ],
    location: {
      lat: 3.919305,
      lng: -56.027783
    }
  },

  {
    name: "Svalbard & Jan Mayen",
    dialCode: "47",
    iso2: "SJ",
    iso3: "SJM",
    emoji: "🇸🇯",
    capital: "Longyearbyen",
    timezone: [
      "Europe/Oslo"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 77.553604,
      lng: 23.670272
    }
  },

  {
    name: "Eswatini",
    dialCode: "268",
    iso2: "SZ",
    iso3: "SWZ",
    emoji: "🇸🇿",
    capital: "Mbabane",
    timezone: [
      "Africa/Johannesburg"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: -26.522503,
      lng: 31.465866
    }
  },

  {
    name: "Sweden",
    dialCode: "46",
    iso2: "SE",
    iso3: "SWE",
    emoji: "🇸🇪",
    capital: "Stockholm",
    timezone: [
      "Europe/Stockholm"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 60.128161,
      lng: 18.643501
    }
  },

  {
    name: "Switzerland",
    dialCode: "41",
    iso2: "CH",
    iso3: "CHE",
    emoji: "🇨🇭",
    capital: "Berne",
    timezone: [
      "Europe/Zurich"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 46.818188,
      lng: 8.227512
    }
  },

  {
    name: "Syria",
    dialCode: "963",
    iso2: "SY",
    iso3: "SYR",
    emoji: "🇸🇾",
    capital: "Damascus",
    timezone: [
      "Asia/Damascus"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: 34.802075,
      lng: 38.996815
    }
  },

  {
    name: "Taiwan",
    dialCode: "886",
    iso2: "TW",
    iso3: "TWN",
    emoji: "🇹🇼",
    capital: "Taipei",
    timezone: [
      "Asia/Taipei"
    ],
    utcOffset: [
      "+08:00"
    ],
    location: {
      lat: 23.69781,
      lng: 120.960515
    }
  },

  {
    name: "Tajikistan",
    dialCode: "992",
    iso2: "TJ",
    iso3: "TJK",
    emoji: "🇹🇯",
    capital: "Dushanbe",
    timezone: [
      "Asia/Dushanbe"
    ],
    utcOffset: [
      "+05:00"
    ],
    location: {
      lat: 38.861034,
      lng: 71.276093
    }
  },

  {
    name: "Tanzania",
    dialCode: "255",
    iso2: "TZ",
    iso3: "TZA",
    emoji: "🇹🇿",
    capital: "Dodoma",
    timezone: [
      "Africa/Nairobi"
    ],
    utcOffset: [
      "+03:00"
    ],
    location: {
      lat: -6.369028,
      lng: 34.888822
    }
  },

  {
    name: "Thailand",
    dialCode: "66",
    iso2: "TH",
    iso3: "THA",
    emoji: "🇹🇭",
    capital: "Bangkok",
    timezone: [
      "Asia/Bangkok"
    ],
    utcOffset: [
      "+07:00"
    ],
    location: {
      lat: 15.870032,
      lng: 100.992541
    }
  },

  {
    name: "Timor-Leste",
    dialCode: "670",
    iso2: "TL",
    iso3: "TLS",
    emoji: "🇹🇱",
    capital: "Dili",
    timezone: [
      "Asia/Dili"
    ],
    utcOffset: [
      "+09:00"
    ],
    location: {
      lat: -8.874217,
      lng: 125.727539
    }
  },

  {
    name: "Togo",
    dialCode: "228",
    iso2: "TG",
    iso3: "TGO",
    emoji: "🇹🇬",
    capital: "Lome",
    timezone: [
      "Africa/Abidjan"
    ],
    utcOffset: [
      "+00:00"
    ],
    location: {
      lat: 8.619543,
      lng: 0.824782
    }
  },

  {
    name: "Tokelau",
    dialCode: "690",
    iso2: "TK",
    iso3: "TKL",
    emoji: "🇹🇰",
    capital: null,
    timezone: [
      "Pacific/Fakaofo"
    ],
    utcOffset: [
      "+13:00"
    ],
    location: {
      lat: -8.967363,
      lng: -171.855881
    }
  },

  {
    name: "Tonga",
    dialCode: "676",
    iso2: "TO",
    iso3: "TON",
    emoji: "🇹🇴",
    capital: "Nuku'alofa",
    timezone: [
      "Pacific/Tongatapu"
    ],
    utcOffset: [
      "+13:00"
    ],
    location: {
      lat: -21.178986,
      lng: -175.198242
    }
  },

  {
    name: "Trinidad & Tobago",
    dialCode: "1868",
    iso2: "TT",
    iso3: "TTO",
    emoji: "🇹🇹",
    capital: "Port of Spain",
    timezone: [
      "America/Puerto_Rico"
    ],
    utcOffset: [
      "-04:00"
    ],
    location: {
      lat: 10.691803,
      lng: -61.222503
    }
  },

  {
    name: "Tunisia",
    dialCode: "216",
    iso2: "TN",
    iso3: "TUN",
    emoji: "🇹🇳",
    capital: "Tunis",
    timezone: [
      "Africa/Tunis"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 33.886917,
      lng: 9.537499
    }
  },

  {
    name: "Turkey",
    dialCode: "90",
    iso2: "TR",
    iso3: "TUR",
    emoji: "🇹🇷",
    capital: "Ankara",
    timezone: [
      "Europe/Istanbul"
    ],
    utcOffset: [
      "+03:00"
    ],
    location: {
      lat: 38.963745,
      lng: 35.243322
    }
  },

  {
    name: "Turkmenistan",
    dialCode: "993",
    iso2: "TM",
    iso3: "TKM",
    emoji: "🇹🇲",
    capital: "Ashgabat",
    timezone: [
      "Asia/Ashgabat"
    ],
    utcOffset: [
      "+05:00"
    ],
    location: {
      lat: 38.969719,
      lng: 59.556278
    }
  },

  {
    name: "Turks & Caicos Islands",
    dialCode: "1649",
    iso2: "TC",
    iso3: "TCA",
    emoji: "🇹🇨",
    capital: "Cockburn Town",
    timezone: [
      "America/Grand_Turk"
    ],
    utcOffset: [
      "-05:00"
    ],
    location: {
      lat: 21.694025,
      lng: -71.797928
    }
  },

  {
    name: "Tuvalu",
    dialCode: "688",
    iso2: "TV",
    iso3: "TUV",
    emoji: "🇹🇻",
    capital: "Funafuti",
    timezone: [
      "Pacific/Funafuti"
    ],
    utcOffset: [
      "+12:00"
    ],
    location: {
      lat: -7.109535,
      lng: 177.64933
    }
  },

  {
    name: "Uganda",
    dialCode: "256",
    iso2: "UG",
    iso3: "UGA",
    emoji: "🇺🇬",
    capital: "Kampala",
    timezone: [
      "Africa/Nairobi"
    ],
    utcOffset: [
      "+03:00"
    ],
    location: {
      lat: 1.373333,
      lng: 32.290275
    }
  },

  {
    name: "Ukraine",
    dialCode: "380",
    iso2: "UA",
    iso3: "UKR",
    emoji: "🇺🇦",
    capital: "Kiev",
    timezone: [
      "Europe/Kiev",
      "Europe/Simferopol",
      "Europe/Uzhgorod",
      "Europe/Zaporozhye"
    ],
    utcOffset: [
      "+02:00",
      "+03:00",
      "+02:00",
      "+02:00"
    ],
    location: {
      lat: 48.379433,
      lng: 31.16558
    }
  },

  {
    name: "United Arab Emirates",
    dialCode: "971",
    iso2: "AE",
    iso3: "ARE",
    emoji: "🇦🇪",
    capital: "Abu Dhabi",
    timezone: [
      "Asia/Dubai"
    ],
    utcOffset: [
      "+04:00"
    ],
    location: {
      lat: 23.424076,
      lng: 53.847818
    }
  },

  {
    name: "UK",
    dialCode: "44",
    iso2: "GB",
    iso3: "GBR",
    emoji: "🇬🇧",
    capital: "London",
    timezone: [
      "Europe/London"
    ],
    utcOffset: [
      "+00:00"
    ],
    location: {
      lat: 55.378051,
      lng: -3.435973
    }
  },

  {
    name: "US",
    dialCode: "1",
    iso2: "US",
    iso3: "USA",
    emoji: "🇺🇸",
    capital: "Washington",
    timezone: [
      "America/Adak",
      "America/Anchorage",
      "America/Boise",
      "America/Chicago",
      "America/Denver",
      "America/Detroit",
      "America/Indiana/Indianapolis",
      "America/Indiana/Knox",
      "America/Indiana/Marengo",
      "America/Indiana/Petersburg",
      "America/Indiana/Tell_City",
      "America/Indiana/Vevay",
      "America/Indiana/Vincennes",
      "America/Indiana/Winamac",
      "America/Juneau",
      "America/Kentucky/Louisville",
      "America/Kentucky/Monticello",
      "America/Los_Angeles",
      "America/Menominee",
      "America/Metlakatla",
      "America/New_York",
      "America/Nome",
      "America/North_Dakota/Beulah",
      "America/North_Dakota/Center",
      "America/North_Dakota/New_Salem",
      "America/Phoenix",
      "America/Sitka",
      "America/Yakutat",
      "Pacific/Honolulu"
    ],
    utcOffset: [
      "-10:00",
      "-09:00",
      "-07:00",
      "-06:00",
      "-07:00",
      "-05:00",
      "-05:00",
      "-06:00",
      "-05:00",
      "-05:00",
      "-06:00",
      "-05:00",
      "-05:00",
      "-05:00",
      "-09:00",
      "-05:00",
      "-05:00",
      "-08:00",
      "-06:00",
      "-09:00",
      "-05:00",
      "-09:00",
      "-06:00",
      "-06:00",
      "-06:00",
      "-07:00",
      "-09:00",
      "-09:00",
      "-10:00"
    ],
    location: {
      lat: 37.09024,
      lng: -95.712891
    }
  },

  {
    name: "Uruguay",
    dialCode: "598",
    iso2: "UY",
    iso3: "URY",
    emoji: "🇺🇾",
    capital: "Montevideo",
    timezone: [
      "America/Montevideo"
    ],
    utcOffset: [
      "-03:00"
    ],
    location: {
      lat: -32.522779,
      lng: -55.765835
    }
  },

  {
    name: "Uzbekistan",
    dialCode: "998",
    iso2: "UZ",
    iso3: "UZB",
    emoji: "🇺🇿",
    capital: "Tashkent",
    timezone: [
      "Asia/Samarkand",
      "Asia/Tashkent"
    ],
    utcOffset: [
      "+05:00",
      "+05:00"
    ],
    location: {
      lat: 41.377491,
      lng: 64.585262
    }
  },

  {
    name: "Vanuatu",
    dialCode: "678",
    iso2: "VU",
    iso3: "VUT",
    emoji: "🇻🇺",
    capital: "Port Vila",
    timezone: [
      "Pacific/Efate"
    ],
    utcOffset: [
      "+11:00"
    ],
    location: {
      lat: -15.376706,
      lng: 166.959158
    }
  },

  {
    name: "Venezuela",
    dialCode: "58",
    iso2: "VE",
    iso3: "VEN",
    emoji: "🇻🇪",
    capital: "Caracas",
    timezone: [
      "America/Caracas"
    ],
    utcOffset: [
      "-04:00"
    ],
    location: {
      lat: 6.42375,
      lng: -66.58973
    }
  },

  {
    name: "Vietnam",
    dialCode: "84",
    iso2: "VN",
    iso3: "VNM",
    emoji: "🇻🇳",
    capital: "Hanoi",
    timezone: [
      "Asia/Bangkok",
      "Asia/Ho_Chi_Minh"
    ],
    utcOffset: [
      "+07:00",
      "+07:00"
    ],
    location: {
      lat: 14.058324,
      lng: 108.277199
    }
  },

  {
    name: "British Virgin Islands",
    dialCode: "1284",
    iso2: "VG",
    iso3: "VGB",
    emoji: "🇻🇬",
    capital: "Road Town",
    timezone: [
      "America/Puerto_Rico"
    ],
    utcOffset: [
      "-04:00"
    ],
    location: {
      lat: 18.420695,
      lng: -64.639968
    }
  },

  {
    name: "U.S. Virgin Islands",
    dialCode: "1340",
    iso2: "VI",
    iso3: "VIR",
    emoji: "🇻🇮",
    capital: "Charlotte Amalie",
    timezone: [
      "America/Puerto_Rico"
    ],
    utcOffset: [
      "-04:00"
    ],
    location: {
      lat: 18.335765,
      lng: -64.896335
    }
  },

  {
    name: "Wallis & Futuna",
    dialCode: "681",
    iso2: "WF",
    iso3: "WLF",
    emoji: "🇼🇫",
    capital: "Mata Utu",
    timezone: [
      "Pacific/Wallis"
    ],
    utcOffset: [
      "+12:00"
    ],
    location: {
      lat: -13.768752,
      lng: -177.156097
    }
  },

  {
    name: "Western Sahara",
    dialCode: null,
    iso2: "EH",
    iso3: "ESH",
    emoji: "🇪🇭",
    capital: null,
    timezone: [
      "Africa/El_Aaiun"
    ],
    utcOffset: [
      "+01:00"
    ],
    location: {
      lat: 24.215527,
      lng: -12.885834
    }
  },

  {
    name: "Yemen",
    dialCode: "967",
    iso2: "YE",
    iso3: "YEM",
    emoji: "🇾🇪",
    capital: "Sanaa",
    timezone: [
      "Asia/Riyadh"
    ],
    utcOffset: [
      "+03:00"
    ],
    location: {
      lat: 15.552727,
      lng: 48.516388
    }
  },

  {
    name: "Zambia",
    dialCode: "260",
    iso2: "ZM",
    iso3: "ZMB",
    emoji: "🇿🇲",
    capital: "Lusaka",
    timezone: [
      "Africa/Maputo"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: -13.133897,
      lng: 27.849332
    }
  },

  {
    name: "Zimbabwe",
    dialCode: "263",
    iso2: "ZW",
    iso3: "ZWE",
    emoji: "🇿🇼",
    capital: "Harare",
    timezone: [
      "Africa/Maputo"
    ],
    utcOffset: [
      "+02:00"
    ],
    location: {
      lat: -19.015438,
      lng: 29.154857
    }
  }
];