import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

// 1. Haversine formula to calculate distance between two coordinates
const getDistance = (lat1, lon1, lat2, lon2, unit = "km") => { // "km" or "ml"
  if (!lat1 || !lon1 || !lat2 || !lon2) return null;

  const toRad = (value) => (value * Math.PI) / 180;

  const R = unit === "ml" ? 3958.8 : 6371; // Radius of Earth in miles or kilometers
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRad(lat1)) *
    Math.cos(toRad(lat2)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c;

  return distance.toFixed(2); // Returns distance with two decimal places
};

// 2. Function to get relative time from a timestamp
const getRelativeTime = (date) => {
  if (!date) return "";

  const now = new Date();
  const tweetDate = new Date(date);
  const diffInSeconds = Math.floor((now - tweetDate) / 1000);

  const intervals = [
    { label: "year", seconds: 31536000 },
    { label: "month", seconds: 2592000 },
    { label: "day", seconds: 86400 },
    { label: "hour", seconds: 3600 },
    { label: "minute", seconds: 60 },
    { label: "second", seconds: 1 },
  ];

  for (const interval of intervals) {
    const count = Math.floor(diffInSeconds / interval.seconds);
    if (count >= 1) {
      return count === 1
        ? `${count} ${interval.label} ago`
        : `${count} ${interval.label}s ago`;
    }
  }

  return "just now";
};

const Card = ({ tweet, tag, distanceUnit, time }) => {
  const [relativeTime, setRelativeTime] = useState("");
  const [distance, setDistance] = useState("");

  const taggedTweetId = tweet.tags
    .find((tag) => tag.startsWith("id/") && tag !== `id/${tweet.id}`)
    ?.split("/")[1] || null;

  // Parse user location from localStorage
  const userLocation = JSON.parse(localStorage.getItem("userLocation") || "{}"); // {latitude: ..., longitude: ...}

  useEffect(() => {
    setRelativeTime(
      getRelativeTime(tweet.createdAt)
    );
  }, [time, tweet.createdAt]);

  useEffect(() => {
    // Calculate and set distance if tweet has location data
    if (tweet.latitude && tweet.longitude && userLocation.latitude && userLocation.longitude) {
      const dist = getDistance(
        userLocation.latitude,
        userLocation.longitude,
        tweet.latitude,
        tweet.longitude,
        distanceUnit
      );
      setDistance(dist);
    } else {
      setDistance("Unknown");
    }

    // Optional: Update relative time every minute
    const interval = setInterval(() => {
      const updatedTime = getRelativeTime(tweet.createdAt);
      setRelativeTime(updatedTime);
    }, 60000); // Update every 60 seconds

    return () => clearInterval(interval);
  }, [tweet, userLocation, distanceUnit]);


  const showDistance = (distance, distanceUnit) => {
    if (distanceUnit === "km") {
      if (distance < 0.5) return `~ 500 meters away`;
      return `~ ${Math.round(distance)} km away`;
    }

    if (distanceUnit === "ml") {
      if (distance < 0.3) return `~ 1500 feet away`;
      const roundedDistance = Math.round(distance);

      return roundedDistance === 1
        ? `~ ${roundedDistance} mile away`
        : `~ ${roundedDistance} miles away`;
    }

    return "";
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}    // Start state
      animate={{ opacity: 1, y: 0 }}      // Animate to
      exit={{ opacity: 0, y: -20 }}       // Exit state
      transition={{ duration: 0.5 }}      // Animation duration
      className="bg-white dark:bg-gray-800 rounded-md p-3 pr-5 shadow-sm mb-2.5 w-full"
    >
      <div className="flex items-start space-x-3">
        {/* Smaller avatar on the left */}
        <img
          src={`https://api.dicebear.com/6.x/identicon/svg?seed=${encodeURIComponent(
            tweet.userId
          )}`}
          alt="avatar"
          className="w-5 h-5 rounded-full bg-gray-200 dark:bg-gray-700"
        />

        {/* Right side for IDs, time, text, footer */}
        <div className="flex-1">
          {/* Top row: tweet IDs on left, time on right */}
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-1">
              {/* Primary tweetId */}

              {taggedTweetId || tag.startsWith("id/")
                ? <span className="text-xs">
                  #{tweet.id.slice(0, 4)}
                </span>
                : <a
                  href={`/id/${tweet.id}`}
                  className="text-blue-600 dark:text-blue-400 text-xs hover:underline"
                >
                  #{tweet.id.slice(0, 4)}
                </a>
              }

              {/* If there's a taggedTweetId, show arrow + link */}
              {taggedTweetId && !tag.startsWith("id/") &&
                <>
                  <span className="text-gray-400 text-xs">→</span>
                  <a
                    href={`/id/${taggedTweetId}`}
                    className="text-blue-600 dark:text-blue-400 text-xs hover:underline"
                  >
                    #{taggedTweetId.slice(0, 4)}
                  </a>
                </>
              }

              {taggedTweetId && tag.startsWith("id/") &&
                <>
                  <span className="text-gray-400 text-xs">→</span>
                  <span className="text-gray-400 text-xs">
                    #{taggedTweetId.slice(0, 4)}
                  </span>
                </>
              }
            </div>

            {/* Time on the far right */}
            <span className="text-xs text-gray-500 dark:text-gray-400">
              {relativeTime}
            </span>
          </div>

          {/* Main tweet text (multiline) */}
          <p className="text-gray-800 dark:text-gray-100 text-sm mt-2 whitespace-pre-wrap break-words">
            {tweet.text}
          </p>

          {/* Footer row: distance & tag count */}
          <div className="flex items-center justify-between mt-2 text-xs text-gray-500 dark:text-gray-400">
            <span>
              {
                userLocation?.latitude && userLocation?.longitude && tweet.latitude && tweet.longitude &&
                showDistance(distance, distanceUnit)
              }

            </span>
            {tweet.taggedCount > 1 &&
              <div className="flex items-center space-x-1">
                <img src="/img/tag.png" alt="tag" className="w-4 h-3" />
                <span>{tweet.taggedCount - 1}</span>
              </div>
            }
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default Card;