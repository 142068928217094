import React, { useEffect, useState } from 'react';
import Header from './Header';
import Main from './Main';
import Footer from './Footer';
import Tagline from './Tagline';
import { setupLocation, setupUser, setupSubscription, setupTag } from '../setup';

const Loading = () => (
  <div className="h-screen w-full flex items-center justify-center">
    <img src="/img/loading.gif" alt="Loading..." />
  </div>
);

const Error = () => (
  <div className="h-screen w-full flex flex-col items-center justify-center space-y-4">
    <div className="text-2xl flex text-red-500">Error!</div>
    <div>
      <span className="text-sm">Oops. Please try again later. </span>
      <a href="/" className="text-sm text-blue-500 hover:underline">Refresh</a>
    </div>
  </div>
);

function App() {
  const [tag, setTag] = useState('');
  const [locationPoint, setLocationPoint] = useState('');
  const [distanceUnit, setDistanceUnit] = useState(() =>
    localStorage.getItem('distanceUnit') || (localStorage.getItem('countryCode2Letter') === 'US' ? 'ml' : 'km'));

  const [userStatus, setUserStatus] = useState('Loading');
  const [locationStatus, setLocationStatus] = useState('Loading');
  const [subscriptionStatus, setSubscriptionStatus] = useState('Loading');

  useEffect(() => {
    const _ = [];
    setupUser().then(() => {
      // State
      setUserStatus(true)
      setLocationPoint(localStorage.getItem('locationPoint') || null);
      // Setup
      setupTag(setTag).then(_.push.bind(_)).catch(console.error)
      setupSubscription(setSubscriptionStatus).then(_.push.bind(_)).catch(console.error)
      setupLocation(setLocationStatus).then(_.push.bind(_)).catch(console.error)
    }).catch(() => setUserStatus('Error'));

    // Setup Dark Mode
    // Matter of change
    const darkMode = localStorage.getItem("darkMode") === "true";
    if (darkMode) {
      document.documentElement.classList.add("dark");
    }

    return () => {
      _.forEach((callback) => callback())
    };
  }, []);

  if (userStatus === 'Loading') return <Loading />;
  if (userStatus === 'Error') return <Error />;

  return (
    <div className="relative w-full bg-gray-100 dark:bg-gray-900 dark:text-gray-100">
      <Header
        tag={tag}
        locationStatus={locationStatus}
        setLocationPoint={setLocationPoint}
        locationPoint={locationPoint}
        distanceUnit={distanceUnit}
        setDistanceUnit={setDistanceUnit}
      />
      <Tagline subscriptionStatus={subscriptionStatus} setTag={setTag} tag={tag} />
      <Main tag={tag} locationPoint={locationPoint} distanceUnit={distanceUnit} />
      <Footer tag={tag} />
    </div>
  );
}

export default App;