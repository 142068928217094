import { apiConnect, apiGetMyGeolocation, apiUpdateWebPushSubscription } from './server.js';
import Filattice from "./filattice.js";
import { countries } from './assets/countries.js'

// --- HELPERS ---

function urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

function compareUint8Arrays(a, b) {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

async function requestUserLocation() {
    if (!('geolocation' in navigator)) {
        console.warn('Geolocation is not supported by your browser.');
        return null;
    }

    const cachedLocation = localStorage.getItem('userLocation');
    const cachedTimestamp = Number(localStorage.getItem('userLocationUpdatedAt'));
    const CACHE_DURATION = 10 * 60 * 1000; // 10 minutes

    if (cachedLocation && cachedTimestamp) {
        const age = Date.now() - cachedTimestamp;
        if (age < CACHE_DURATION) {
            return JSON.parse(cachedLocation);
        }
    }

    let permissionState = 'prompt';
    if (navigator.permissions && navigator.permissions.query) {
        try {
            const permission = await navigator.permissions.query({ name: 'geolocation' });
            permissionState = permission.state;
        } catch (err) {
            console.warn('Permissions API error or not supported:', err);
        }
    }

    if (permissionState === 'denied') {
        console.warn('Geolocation permission denied.');
        return null;
    }

    // Attempt to get current browser location
    const getCurrentPosition = () =>
        new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(resolve, reject);
        });

    try {
        const position = await getCurrentPosition();
        const { latitude, longitude } = position.coords;
        localStorage.setItem('userLocation', JSON.stringify({ latitude, longitude }));
        localStorage.setItem('userLocationUpdatedAt', Date.now().toString());
        return { latitude, longitude };
    } catch (error) {
        console.error('Error getting geolocation:', error);
        return null;
    }
}

// --- EXPORTS ---

export const setupUser = async () => {
    const storedBearer = localStorage.getItem('authenticationBearer');
    const storedCountryUpdated = localStorage.getItem('countryCode2LetterUpdatedAt');
    const storedBearerUpdated = Number(localStorage.getItem('authenticationBearerUpdatedAt'));

    // Re-auth if we have no token or if older than 1 day
    if (!storedBearer || !storedCountryUpdated || !storedBearerUpdated ||
        Date.now() - storedBearerUpdated > 1000 * 60 * 60 * 12) {

        await apiConnect({});
    }

    return () => { }
};

// We dont subscribe to changes, just get the current value on every vi
export const setupLocation = async (callback) => {
    const callbackReturn = (status) => {
        callback(status);
        return () => { };
    }

    const userLocation = await requestUserLocation();
    const userLocationByIp =
        !userLocation &&
        (
            !localStorage.getItem('userLocationByIp') ||
            !localStorage.getItem('userLocationByIpUpdatedAt') ||
            Date.now() - Number(localStorage.getItem('userLocationByIpUpdatedAt')) > 1000 * 60 * 10
        ) &&
        await apiGetMyGeolocation();

    const location = userLocation || userLocationByIp?.data?.location;

    if (userLocationByIp) {
        localStorage.setItem('userLocationByIp', JSON.stringify(userLocationByIp));
        localStorage.setItem('userLocationByIpUpdatedAt', Date.now().toString());
    }

    if (!location) {
        localStorage.removeItem('userLocation');
        localStorage.removeItem('userLocationUpdatedAt');
        localStorage.removeItem('userLocationByIp');
        localStorage.removeItem('userLocationByIpUpdatedAt');
        localStorage.removeItem('filatticeLocations');
        localStorage.removeItem('filatticeLocationsUpdatedAt');

        return callbackReturn('Denied');
    }

    if (localStorage.getItem('filatticeLocations') &&
        localStorage.getItem('filatticeLocationsUpdatedAt') &&
        Date.now() - Number(localStorage.getItem('filatticeLocationsUpdatedAt')) < 1000 * 60 * 10) {

        return callbackReturn('Granted');
    }

    const { latitude, longitude } = location;
    const filatticeLocations = [];

    new Filattice(10_000_000_000)
        .nearbyPoints([latitude, longitude])
        .forEach(([lat, lng, dist, i]) => {
            filatticeLocations.push(`10b${i}`);
        });

    new Filattice(100_000_000)
        .nearbyPoints([latitude, longitude])
        .forEach(([lat, lng, dist, i]) => {
            filatticeLocations.push(`100m${i}`);
        });

    new Filattice(1_000_000)
        .nearbyPoints([latitude, longitude])
        .forEach(([lat, lng, dist, i]) => {
            filatticeLocations.push(`1m${i}`);
        });

    localStorage.setItem('filatticeLocations', JSON.stringify(filatticeLocations));
    localStorage.setItem('filatticeLocationsUpdatedAt', Date.now().toString());

    return callbackReturn('Granted');
};


export const setupSubscription = async (callback) => {
    // Check if service workers and push messaging are supported
    if (!('serviceWorker' in navigator)) {
        console.error('Service workers are not supported by this browser.');
        callback('Denied');
        // Even if not supported, return a cleanup function
        return () => { };
    }

    if (!('PushManager' in window)) {
        console.error('Push messaging is not supported by this browser.');
        callback('Denied');
        // Even if not supported, return a cleanup function
        return () => { };
    }

    let registration;

    try {
        // Register the service worker
        registration = await navigator.serviceWorker.register('/service-worker.js');
        // console.log('Service Worker registered:', registration);
    } catch (error) {
        console.error('Service Worker registration failed:', error);
        callback('Denied');
        // Even if registration fails, return a cleanup function
        return () => { };
    }

    // Inside setup.js where you define `subscribeUser`
    const subscribeUser = async () => {
        try {
            // 1. Request notification permission from the user
            const permission = await Notification.requestPermission();

            if (permission !== 'granted') {
                console.warn('Notification permission not granted.');
                await apiUpdateWebPushSubscription({ webPushSubscription: null });
                localStorage.removeItem('webPushSubscription');
                localStorage.removeItem('webPushSubscriptionUpdatedAt');
                callback('Denied');
                return;
            }

            // 2. Check if there's an existing subscription
            const existingSubscription = await registration.pushManager.getSubscription();
            const desiredKey = urlBase64ToUint8Array(process.env.REACT_APP_QAOS_VAPID_PUBLIC_KEY);

            if (existingSubscription) {
                // Compare the existing key with the desired key
                const existingKey = existingSubscription.options.applicationServerKey;
                if (existingKey && compareUint8Arrays(new Uint8Array(existingKey), desiredKey)) {
                    // The keys match; reuse the existing subscription
                    // console.log('Existing subscription is valid. Reusing it.');

                    // Optionally update on server again if needed
                    await apiUpdateWebPushSubscription({ webPushSubscription: existingSubscription });
                    localStorage.setItem('webPushSubscription', JSON.stringify(existingSubscription));
                    localStorage.setItem('webPushSubscriptionUpdatedAt', Date.now().toString());

                    callback('Granted');
                    return;
                } else {
                    // The keys differ; unsubscribe before creating a new subscription
                    // console.log('Existing subscription key differs. Unsubscribing...');
                    await existingSubscription.unsubscribe();
                    await apiUpdateWebPushSubscription({ webPushSubscription: null });
                }
            }

            // 3. If no existing subscription or old key unsubscribed, create a new subscription
            const newSubscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: desiredKey,
            });

            // console.log('New push subscription successful:', newSubscription);

            // 4. Send the new subscription to your server
            await apiUpdateWebPushSubscription({ webPushSubscription: newSubscription });
            localStorage.setItem('webPushSubscription', JSON.stringify(newSubscription));
            localStorage.setItem('webPushSubscriptionUpdatedAt', Date.now().toString());

            callback('Granted');
        } catch (error) {
            console.error('Failed to subscribe the user:', error);
            callback('Denied');
        }
    };

    // Initial subscription attempt
    if (localStorage.getItem('webPushSubscription') &&
        localStorage.getItem('webPushSubscriptionUpdatedAt') &&
        Date.now() - Number(localStorage.getItem('webPushSubscriptionUpdatedAt')) < 1000 * 60 * 60 * 24) {

        callback('Granted');
    } else {
        await subscribeUser();
    }

    // Listen for changes in the notification permission
    let permissionStatus;
    try {
        permissionStatus = await navigator.permissions.query({ name: 'notifications' });
    } catch (err) {
        console.warn('Permissions API error or not supported:', err);
    }

    const handlePermissionChange = async (event) => {
        // console.log('Notification permission changed to:', event.target.state);

        if (event.target.state === 'granted') {
            try {
                // Re-subscribe the user
                const subscription = await registration.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: urlBase64ToUint8Array(process.env.REACT_APP_QAOS_VAPID_PUBLIC_KEY),
                });
                // console.log('Re-subscribed to push notifications:', subscription);
                await apiUpdateWebPushSubscription({ webPushSubscription: subscription });
                localStorage.setItem('webPushSubscription', JSON.stringify(subscription));
                localStorage.setItem('webPushSubscriptionUpdatedAt', Date.now().toString());

                callback('Granted');
            } catch (err) {
                console.error('Re-subscription failed:', err);

                callback('Denied');
            }
        } else if (event.target.state === 'denied') {
            try {
                // console.log('Unsubscribed from push notifications.');
                await apiUpdateWebPushSubscription({ webPushSubscription: null });
                localStorage.removeItem('webPushSubscription');
                localStorage.removeItem('webPushSubscriptionUpdatedAt');

                callback('Denied');
            } catch (err) {

                console.error('Unsubscription failed:', err);
            }
        }
    };

    if (permissionStatus) {
        permissionStatus.onchange = handlePermissionChange;
    }

    // Return an unsubscribe function to clean up listeners and subscriptions
    return () => {
        if (permissionStatus && handlePermissionChange) {
            permissionStatus.onchange = null;
        }
        if (registration && registration.pushManager) {
            registration.pushManager.getSubscription().then(async (currentSubscription) => {
                if (currentSubscription) {
                    await currentSubscription.unsubscribe();
                }
            }).catch(err => {
                console.error('Error accessing push subscription:', err);
            });
        }
    };
};

export const setupTag = async (callback) => {
    function processTag() {
        // Check if the Got from the Router
        const path = window.location.pathname.toLowerCase().replace(/^\//, '').replace(/\/$/, '');

        if (path !== '') {
            // find the Path
            for (const pattern of [
                /^in\/([a-z]{3})\/([a-z0-9-]*)$/,
                /^id\/([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12})$/,
            ]) {
                const match = path.match(pattern);
                if (match) return callback(match[0]);
            }

            window.history.pushState(null, '', '');
            window.location.pathname = '';
        }

        // Get frorm Localstorage
        if (localStorage.getItem('tag')) {
            return callback(localStorage.getItem('tag'));
        }

        // save dynamically
        const countryCode2Letter = localStorage.getItem('countryCode2Letter');
        const countryCode3Letter = countries.find(country => country.iso2 === countryCode2Letter)?.iso3

        callback(`in/${countryCode3Letter || 'www'}/stream`.toLowerCase());
    }

    // Initial Call
    processTag();

    // Subscribtion
    const popstateCallback = () => processTag();
    window.addEventListener('popstate', popstateCallback);

    return () => window.removeEventListener('popstate', popstateCallback);
}
