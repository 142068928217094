import React, { useState, useEffect } from 'react';
import Settings from './Settings';

const everyLocationOptions = [
  { value: null, km: '🌐 Global', ml: '🌐 Global' },
  { value: '1m', km: '~25 km', ml: '~15 miles' },
  { value: '100m', km: '~3 km', ml: '~2 miles' },
  { value: '10b', km: '~500 metr', ml: '~1500 feet' },
];

const Header = ({
  tag,
  locationStatus,
  setLocationPoint,
  locationPoint,
  setDistanceUnit,
  distanceUnit,
}) => {
  const [distanceOptions, setDistanceOptions] = useState([]);
  const [popupSetting, setPopupSetting] = useState(false);

  useEffect(() => {
    if (locationStatus !== 'Granted') return;

    const json = localStorage.getItem('filatticeLocations')
    const filatticeLocations = json ? JSON.parse(json) : [];

    // set distances
    setDistanceOptions(
      everyLocationOptions.filter((option) => {
        if (option.value === null) return true;
        return filatticeLocations.some((location) => location.startsWith(option.value))
      })
    )

    // get location point
    const locationPoint = localStorage.getItem('locationPoint');
    if (locationPoint && !filatticeLocations.includes(locationPoint)) {
      localStorage.removeItem('locationPoint');
      setLocationPoint(null);
    }

  }, [locationStatus, setLocationPoint]);

  const onDistanceChange = (e) => {
    const distance = e.target.value;
    const json = localStorage.getItem('filatticeLocations')
    const filatticeLocations = json ? JSON.parse(json) : [];

    const locationPoint = filatticeLocations.find((location) => location.startsWith(distance));

    if (locationPoint) {
      localStorage.setItem('locationPoint', locationPoint);
      setLocationPoint(locationPoint);
    } else {
      localStorage.removeItem('locationPoint');
      setLocationPoint(null);
    }
  }

  return (
    <div>
      {popupSetting && <Settings
        setDistanceUnit={setDistanceUnit}
        distanceUnit={distanceUnit}
        onClose={() => setPopupSetting(false)} />}
      <header
        className="
          fixed top-0 left-0 w-full bg-white dark:bg-gray-800 shadow-md z-10
        "
      >
        <div
          className="
            max-w-screen-lg mx-auto h-12 flex items-center justify-between
            px-3
          "
        >
          {/* Left Section */}
          <div className="flex items-center min-w-0 space-x-2">
            <a className="relative" href="/">
              <img
                src="/logos/hashtag-64.png"
                alt="Twitch Logo"
                className="w-6 h-6 flex-shrink-0"
              />
            </a>
            <a className="relative font-semibold text-sm" href="/">
              qaos.app
            </a>
          </div>

          {/* Right Section (Switcher) */}
          <div className="flex items-center space-x-2">
            {/* Dropdown for distance */}
            <div className="flex items-center space-x-2">
              {locationStatus === 'Granted' && tag.startsWith('in/') && (
                <>
                <span className="dark:text-gray-100 text-sm">Distance →</span>
                <select
                  onChange={onDistanceChange}
                  id="distance"
                  className="bg-gray-100 dark:bg-gray-700 dark:text-gray-100 border-none rounded px-1 py-0.5 text-sm"
                  value={distanceOptions.find((option) => locationPoint && locationPoint.startsWith(option.value))?.value}
                >
                  {distanceOptions.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {distanceUnit === 'km' ? option.km : option.ml}
                    </option>
                  ))}
                </select>
                
                </>
                )}
            </div>

            {/* Dark Mode Toggle */}
            <button
              onClick={() => setPopupSetting(!popupSetting)}
              className="font-semibold dark:text-gray-100 bg-transparent border-none cursor-pointer"
            >
              <img
                src="/img/settings.png"
                alt="Twitch Logo"
                className="w-4 h-4 flex-shrink-0 mr-1 ml-2"
                style={{ width: '18px', height: '18px' }}
              />
            </button>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;