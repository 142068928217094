import React, { useState, useEffect, useRef, useCallback } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Card from "./Card";
import useIsMobile from './UseIsMobile';
import { apiGetTweets, apiGetTweetsSse } from "../server";

const MAX_PENDING_TWEETS = 5;

const NewMessageNotification = ({ onClick }) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 50 }}
      className="fixed z-50 right-4 bottom-20 cursor-pointer"
      onClick={onClick}
    >
      <img src="/img/new.png" alt="New messages" className="w-8 h-8 inline-block mr-2" />
    </motion.div>
  );
};

const debounce = (func, wait = 100) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

const Main = ({ tag, locationPoint, distanceUnit }) => {
  const isMobile = useIsMobile();
  const endTag = useCallback(
    () => tag.startsWith('in/') ? tag + (locationPoint ? `@${locationPoint}` : "") : tag,
    [tag, locationPoint]
  );

  const [tweets, setTweets] = useState([]);
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [isUserAtBottom, setIsUserAtBottom] = useState(true);
  const [hasMoreOlder, setHasMoreOlder] = useState(true);
  const [pendingNewTweets, setPendingNewTweets] = useState([]);
  const [time, setTime] = useState(0);

  const containerRef = useRef(null);

  const handleNotificationClick = () => {
    setNotificationVisible(false);
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  const processingPendingTweetsRef = useRef(false);

  const appendPendingNewTweets = useCallback(async () => {
    if (!pendingNewTweets.length) return;
    if (processingPendingTweetsRef.current) return;
    processingPendingTweetsRef.current = true;

    // Grab the old (current) scroll offset
    const oldScrollTop = document.documentElement.scrollTop;
    const previousScrollHeight = document.documentElement.scrollHeight;

    // Append new tweets
    setTweets((prev) => [...prev, ...pendingNewTweets]);
    setPendingNewTweets([]);

    // Wait for DOM updates
    await new Promise((resolve) => setTimeout(resolve, 50));

    processingPendingTweetsRef.current = false;

    // Compute how much the page height has grown
    const newScrollHeight = document.documentElement.scrollHeight;
    const scrollDifference = newScrollHeight - previousScrollHeight;

    // Keep the user at the same visual offset
    window.scrollTo({
      top: oldScrollTop + scrollDifference,
      behavior: "auto",
    });
  }, [pendingNewTweets]);

  const refreshingTweetsRef = useRef(false);
  const refreshTweets = useCallback(async () => {
    if (refreshingTweetsRef.current) return;

    try {
      const result = await apiGetTweets({ tag: endTag(), index: null });
      const data = result?.data?.reverse() || [];

      setTweets(data);
      setPendingNewTweets([]);
      setHasMoreOlder(true);

      await new Promise((resolve) => setTimeout(resolve, 50));
      refreshingTweetsRef.current = false

      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "auto",
      });
    } catch (error) {

      refreshingTweetsRef.current = false
      console.error("Error refreshing tweets:", error);
    }
  }, [endTag]);

  const showNewerTweets = useCallback(() => {
    if (pendingNewTweets.length > 0) {
      if (pendingNewTweets.length > MAX_PENDING_TWEETS) {
        return refreshTweets();
      }

      appendPendingNewTweets();
      setPendingNewTweets([]);
    }
  }, [pendingNewTweets, refreshTweets, appendPendingNewTweets]);


  const loadingTweetsRef = useRef(false);
  const loadOlderTweets = useCallback(async () => {
    if (!hasMoreOlder) return;
    if (loadingTweetsRef.current) return;

    try {
      // Fetch older tweets
      const result = await apiGetTweets({ tag: endTag(), index: tweets[0]?.index });
      const data = result?.data?.reverse() || [];

      if (!data.length) {
        return setHasMoreOlder(false);
      }

      const currentScrollHeight = document.documentElement.scrollHeight;

      setTweets((prev) => [...data, ...prev]);

      // Wait for DOM updates and animations to complete
      await new Promise((resolve) => setTimeout(resolve, 50));
      loadingTweetsRef.current = false;

      // Scroll to maintain position
      window.scrollTo({
        top: document.documentElement.scrollHeight - currentScrollHeight,
        behavior: "instant",
      });

    } catch (error) {
      console.error("Error loading older tweets on window scroll:", error);
      setHasMoreOlder(false);
      loadingTweetsRef.current = false;
    }
  }, [hasMoreOlder, endTag, tweets]);

  const handleWindowScroll = useCallback(() => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const docHeight = document.documentElement.scrollHeight;

    // Update isUserAtBottom state
    setIsUserAtBottom(scrollTop + windowHeight >= docHeight - 20);

    // Detect if user has scrolled to the top
    if (scrollTop === 0) {
      // console.log("Window has reached the top");
      loadOlderTweets();
    }

    // Detect if user has scrolled to the bottom
    if (scrollTop + windowHeight >= docHeight - 10) {
      // console.log("Window has reached the bottom");
      setNotificationVisible(false);
      showNewerTweets();
    }
  }, [loadOlderTweets, showNewerTweets]);

  // Debounced version of the scroll handler using useCallback and debounce
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleWindowScroll = useCallback(debounce(handleWindowScroll, 250), [
    handleWindowScroll,
  ]);

  // Initial
  useEffect(() => {
    refreshTweets();

    const timer = setInterval(() => {
      setTime(new Date().getTime());
    }, 1000 * 20);

    return () => {
      clearInterval(timer);
    }

  }, [tag, locationPoint, endTag, refreshTweets]);

  // Scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", debouncedHandleWindowScroll);

    return () => {
      window.removeEventListener("scroll", debouncedHandleWindowScroll);
    };
  }, [debouncedHandleWindowScroll, isUserAtBottom]);

  const isUserAtBottomRef = useRef(isUserAtBottom);

  useEffect(() => {
    isUserAtBottomRef.current = isUserAtBottom;
  }, [isUserAtBottom]);

  // Store the EventSource
  const sourceRef = useRef(null);
  useEffect(() => {
    if (sourceRef.current) {
      sourceRef.current.close();
    }

    (async function openSse() {
      try {
        sourceRef.current = await apiGetTweetsSse({ tag: endTag() });
        sourceRef.current.onmessage = (event) => {
          if (!event.data || event.data === ': keep-alive') return;

          try {
            const newTweet = JSON.parse(event.data);

            // Use a ref to get the latest isUserAtBottom value
            if (isUserAtBottomRef.current) {
              setTweets((prev) => [...prev, newTweet]);

              // 
              return setTimeout(() => {
                requestAnimationFrame(() => {
                  window.scrollTo({
                    top: document.documentElement.scrollHeight,
                    behavior: "auto",
                  });
                });
              }, 20)
            }

            // If user is not at the bottom, show a notification
            setNotificationVisible(true);

            // Stash it as "unread" if under the limit
            setPendingNewTweets((prev) => {
              if (prev.length < MAX_PENDING_TWEETS) {
                return [...prev, newTweet];
              } else {
                // Exceeded max pending tweets, do not store more
                return prev;
              }
            });
          } catch (parseErr) {
            console.error("Error parsing SSE data:", parseErr);
          }
        };

        sourceRef.current.onerror = (err) => {
          console.error("SSE error:", err);
          sourceRef.current.close();
          setTimeout(() => {
            openSse();
          }, 5000);
        };
      } catch (error) {
        console.error("Error setting up SSE:", error);
      }
    })();

    return () => {
      if (sourceRef.current) {
        sourceRef.current.close();
      }
    };
  }, [tag, locationPoint, endTag]);

  // 1) Use an effect that only sets the custom CSS variable when on mobile.
  useEffect(() => {
    if (!isMobile) return;

    let resizeTimer;

    function setVh() {
      const vhUnit = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vhUnit}px`);
    }

    function handleResize() {
      // Clear any pending timers
      if (resizeTimer) clearTimeout(resizeTimer);

      // Debounce: Wait 300ms after the last resize event
      resizeTimer = setTimeout(() => {
        setVh();
      }, 10);
    }

    // Initial run
    setVh();

    // Attach debounced handler
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      if (resizeTimer) clearTimeout(resizeTimer);
    };
  }, [isMobile]);

  return (
    <>
      <main
        className="
          pt-24 pb-16
          min-h-screen
          max-w-screen-lg mx-auto
          bg-gray-100 dark:bg-gray-900
          flex
          flex-col-reverse
        "
        style={isMobile ? { minHeight: "calc(var(--vh) * 100)" } : undefined}
      >
        <div ref={containerRef} className="max-w-screen-lg mx-auto w-full">
          <AnimatePresence initial={false}>
            {tweets.map((tweet) => (
              <Card key={tweet.id} tweet={tweet} tag={tag} distanceUnit={distanceUnit} time={time} />
            ))}
          </AnimatePresence>
        </div>
      </main>
      {notificationVisible && (
        <AnimatePresence>
          <NewMessageNotification onClick={handleNotificationClick} />
        </AnimatePresence>
      )}
    </>
  );
};

export default Main;