import React, { useState, useEffect } from "react";
import { ReactComponent as NotificationBellOnIcon } from "../assets/notification-bell-on.svg";
import { ReactComponent as NotificationBellOffIcon } from "../assets/notification-bell-off.svg";
import TagSettings from "./TagSettings";
import { apiGetSubscribedTags, apiSubscribeToTag, apiUnsubscribeTag } from "../server";

const Tagline = ({ setTag, tag, subscriptionStatus }) => {
  const [subscribed, setSubscribed] = useState(false);
  const [tagSettings, setTagSettings] = useState(false);

  useEffect(() => {
    apiGetSubscribedTags().then((res) => {
      const { subscribedTags } = res.data;
      if (subscribedTags.includes(tag)) {
        setSubscribed(true);
      }
    }).catch((error) => {
      console.error(error);
    })

  }, [subscriptionStatus, tag]);

  useEffect(() => {
    if (subscriptionStatus !== 'Granted') return;
    if (subscribed) {
      apiSubscribeToTag({ tag }).catch(console.error)
    } else {
      apiUnsubscribeTag({ tag }).catch(console.error)
    }
  }, [subscribed, tag, subscriptionStatus]);

  const handleToggle = () => {
    setSubscribed((prev) => !prev);
  };

  return (
    <div>
      {tagSettings && <TagSettings setTag={setTag} tag={tag} onClose={(t) => { setTagSettings(false) }} />}
      <div
        className="fixed top-12 left-0 w-full bg-gray-100 dark:bg-gray-900 shadow-sm h-8 shadow-gray-200 dark:shadow-gray-800"
        style={{ zIndex: 5 }}
      >
        <div className="max-w-screen-lg mx-auto flex justify-between items-center px-3">
          {/* Left-aligned Button */}
          <div
            className="
              relative flex-1 min-w-0 text-md dark:text-gray-100
              whitespace-nowrap overflow-hidden leading-8
            "
            style={{
              direction: "rtl",
              textAlign: "left",
            }}
          >
            {
              tag.startsWith("id/")
                ? <div>
                  <span className="relative ml-3 text-sm">{`\u200E#${tag.slice(3, 7)}`}</span>

                  <button type="button"
                    onClick={() => window.history.go(-1)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none" viewBox="0 0 20 20"
                      strokeWidth={2} stroke="currentColor"
                      className="w-4 h-4 pt-1 text-blue-600 dark:text-blue-400"
                    >
                      <path
                        strokeLinecap="round" strokeLinejoin="round"
                        d="M10.5 19.5l-7.5-7.5 7.5-7.5M3 12h18"
                      />
                    </svg>
                  </button>

                </div>
                : <button className="relative mr-2 text-sm text-blue-600 dark:text-blue-400" onClick={() => setTagSettings(!tagSettings)}>{`\u200E#${tag}`}</button>
            }
          </div>

          {/* Right-aligned Subscription Switcher */}
          {subscriptionStatus === 'Granted' &&
            <div className="flex items-center space-x-2">
              <button
                type="button"
                onClick={handleToggle}
                aria-pressed={subscribed}
                className={`
              relative inline-flex items-center
              w-10 h-5
              rounded-full cursor-pointer
              transition-colors duration-300
              ${subscribed ? "bg-gray-300 dark:bg-gray-500" : "bg-gray-300 dark:bg-gray-500"}
            `}
              >
                {/* The circle (thumb) that moves left and right */}
                <span
                  className={`
                absolute inline-flex items-center justify-center
                w-6 h-6 rounded-full shadow
                transform transition-transform duration-300
                ${subscribed
                      ? "translate-x-[1rem] bg-white dark:bg-gray-200"
                      : "translate-x-0 bg-white dark:bg-gray-600"
                    }
              `}
                >
                  {subscribed ? (
                    <NotificationBellOnIcon className="w-4 h-4" />
                  ) : (
                    <NotificationBellOffIcon className="w-4 h-4" />
                  )}
                </span>
              </button>
            </div>}
        </div>
      </div>
    </div>
  );
};

export default Tagline;