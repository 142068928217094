import React, { useState, useEffect } from "react";
import { countries } from "../assets/countries";

const TagSettings = ({ onClose, tag, setTag }) => {
  const isMobile = window.innerWidth <= 768;

  const [tagRegion, setTagRegion] = useState('www'); // www
  const [tagStream, setTagStream] = useState('stream'); // stream

  const [streamError, setStreamError] = useState(null);
  const [defaultChecked, setDefaultChecked] = useState(false);

  useEffect(() => {
    const match = tag && tag.match(/^in\/([A-Za-z]{3})\/([0-9a-z-\\/]+)$/);
    if (match) {
      const [, storedRegion, storedStream] = match;

      setTagRegion(storedRegion);
      setTagStream(storedStream);
    }
  }, [tag]);

  const validateTagStream = (value) => {
    const regex = /^[0-9a-z-/]{2,128}$/;
    if (!regex.test(value)) {
      return "Tag must be 2-128 chars, only: 0-9 a-z -";
    }
    return null;
  };

  const validateTagRegion = (r) => r && r.trim() !== "";

  const handleRegionChange = (e) => {
    const next = e.target.value;
    setTagRegion(next);
    // If tagRegion or tagStream becomes invalid => disable & uncheck the box
    if (!validateTagRegion(next) || validateTagStream(tagStream) !== "") {
      setDefaultChecked(false);
    }
  };

  const handleTagChange = (e) => {
    // 1. Convert to lowercase
    let value = e.target.value.toLowerCase();
    
    // 2. Replace any sequence of non-[a-z0-9] with a dash
    value = value.replace(/[^a-z0-9]+/g, '-');
    
    // Update state with the sanitized value
    setTagStream(value);
  
    // Validate
    const error = validateTagStream(value);
    setStreamError(error);
    
    // If invalid, uncheck "Default"
    if (!validateTagRegion(tagRegion) || error !== null) {
      setDefaultChecked(false);
    }
  };

  const handleDefaultChange = () => {
    // Flip it only if tagRegion & tag are valid
    if (validateTagRegion(tagRegion) && !validateTagStream(tagStream)) {
      setDefaultChecked(!defaultChecked);
    }
  };

  const handleShow = () => {
    if (streamError) return;
    // build final path
    const combinedPath = `in/${tagRegion || 'www'}/${tagStream}`;

    // if checkbox is checked => we store another key
    if (defaultChecked) {
      localStorage.setItem("tag", combinedPath);
    }

    // set window.location.pathname
    window.history.pushState(null, "", `/${combinedPath}`);
    setTag(combinedPath)

    // pass the final path to onClose
    onClose(combinedPath);
  };

  const handleOverlayClick = () => onClose(null);
  const handlePopupContentClick = (e) => e.stopPropagation();

  const isCheckboxDisabled = !validateTagRegion(tagRegion) || streamError !== null;
  const isButtonDisabled = !!streamError; // button disabled if there's a tag error

  return (
    <div
      className="
        fixed inset-0
        flex items-center justify-center
        bg-black bg-opacity-30
        dark:bg-black dark:bg-opacity-50
        z-40
      "
      onClick={handleOverlayClick}
    >
      <div
        onClick={handlePopupContentClick}
        className={`
          relative
          bg-white dark:bg-gray-800
          ${isMobile
            ? "w-full h-full p-4"
            : "w-[500px] p-6 shadow-lg rounded max-h-[80vh] overflow-y-auto"
          }
        `}
      >
        {/* Header */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold dark:text-gray-100">Tag Settings</h2>
          <button
            onClick={() => onClose(null)}
            className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
          >
            <span className="sr-only">Close</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        {/* Content */}
        <div className="text-gray-600 dark:text-gray-300 flex flex-col space-y-4">
          {/* Region */}
          <div className="flex flex-col">
            <label htmlFor="tagRegion" className="text-sm mb-1">
              Region:
            </label>
            <select
              id="tagRegion"
              value={tagRegion}
              onChange={handleRegionChange}
              className="
                bg-gray-100 dark:bg-gray-700 dark:text-gray-100
                border-none rounded px-3 py-1
              "
            >
              <option value="www">Auto</option>
              {countries.map((c) => (
                <option key={c.iso3.toLowerCase()} value={c.iso3.toLowerCase()}>
                  {c.emoji} {c.name}
                </option>
              ))}
            </select>
          </div>

          {/* Tag */}
          <div className="flex flex-col">
            <label htmlFor="tagInput" className="text-sm mb-1">
              Tag:
            </label>
            <input
              id="tagInput"
              type="text"
              value={tagStream}
              onChange={handleTagChange}
              className="
                bg-gray-100 dark:bg-gray-700 dark:text-gray-100
                border-none rounded px-3 py-1
              "
              placeholder="e.g. stream"
            />
            {streamError && (
              <p className="text-red-500 text-sm mt-1">{streamError}</p>
            )}
          </div>
        </div>

        {/* Footer / Buttons */}
        <div className="mt-6 flex justify-end items-center space-x-4">
          {/* Default Checkbox (left of button) */}
          <div className="flex items-center me-4">
            <input checked={defaultChecked}
              onChange={handleDefaultChange}
              disabled={isCheckboxDisabled} id="defaultCheckbox" type="checkbox" className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-green-500 dark:focus:ring-green-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
            <label htmlFor="defaultCheckbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Save As Default</label>
          </div>

          <button
            onClick={handleShow}
            disabled={isButtonDisabled}
            className={`
             text-white bg-gradient-to-r from-teal-400 via-teal-500 to-teal-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-teal-300 dark:focus:ring-teal-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2
              ${isButtonDisabled
                ? "bg-gray-500 cursor-not-allowed"
                : "bg-green-600 hover:bg-green-700"
              }
            `}
          >
            Show
          </button>
        </div>
      </div>
    </div>
  );
};

export default TagSettings;