import React, { useState, useEffect } from "react";

const Settings = ({ onClose, title, distanceUnit, setDistanceUnit }) => {
  const isMobile = window.innerWidth <= 768;

  // 1) DARK MODE state initialization from localStorage
  const [darkMode, setDarkMode] = useState(() => {
    // Convert the string "true"/"false" back to boolean
    return localStorage.getItem("darkMode") === "true";
  });

  // 3) Sync darkMode with HTML class and save to localStorage
  useEffect(() => {
    if (darkMode) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
    // Persist darkMode preference
    localStorage.setItem("darkMode", darkMode);
  }, [darkMode]);

  // 4) Persist distanceUnit to localStorage
  useEffect(() => {
    localStorage.setItem("distanceUnit", distanceUnit);
  }, [distanceUnit]);

  // Handlers
  const handleDarkModeToggle = () => {
    setDarkMode(!darkMode);
  };

  const handleDistanceChange = (e) => {
    setDistanceUnit(e.target.value);
  };

  const handleOverlayClick = () => onClose({ distanceUnit, darkMode });
  const handlePopupContentClick = (e) => e.stopPropagation();

  return (
    <div
      className="
        fixed inset-0
        flex items-center justify-center
        bg-black bg-opacity-30
        dark:bg-black dark:bg-opacity-50
        z-30
      "
      onClick={handleOverlayClick}
    >
      <div
        onClick={handlePopupContentClick}
        className={`
          relative
          bg-white dark:bg-gray-800
          ${isMobile
            ? "w-full h-full p-4"
            : "w-[500px] p-6 shadow-lg rounded max-h-[80vh] overflow-y-auto"
          }
        `}
      >
        {/* Header */}
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold dark:text-gray-100">{title}</h2>
          <button
            onClick={() => onClose({ distanceUnit, darkMode })}
            className="text-gray-500 hover:text-gray-700 dark:hover:text-gray-300"
          >
            <span className="sr-only">Close</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        {/* Content */}
        <div className="text-gray-600 dark:text-gray-300 flex flex-col space-y-4">
          {/* Dark Mode Switch */}
          <div className="flex items-center justify-between">
            <label htmlFor="darkModeSwitcher" className="text-sm">
              Dark Mode
            </label>
            <div>
              <input
                type="checkbox"
                id="darkModeSwitcher"
                checked={darkMode}
                onChange={handleDarkModeToggle}
                className="hidden"
              />
              <div
                onClick={handleDarkModeToggle}
                className={`
                  relative w-12 h-6
                  rounded-full cursor-pointer
                  ${darkMode ? "bg-green-500" : "bg-gray-300"}
                  transition-colors duration-300
                `}
              >
                <div
                  className={`
                    absolute top-1 left-1 w-4 h-4
                    bg-white rounded-full shadow
                    transform transition-transform duration-300
                    ${darkMode ? "translate-x-6" : ""}
                  `}
                />
              </div>
            </div>
          </div>

          {/* Distance Unit Select */}
          <div className="flex items-center justify-between">
            <label htmlFor="distanceUnit" className="text-sm">
              Distance Unit
            </label>
            <select
              id="distanceUnit"
              className="
                bg-gray-100 dark:bg-gray-700 dark:text-gray-100
                border-none rounded px-3 py-1
              "
              value={distanceUnit}
              onChange={handleDistanceChange}
            >
              <option value="km">Kilometers</option>
              <option value="ml">Miles</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;