import React, { useState, useRef, useEffect } from 'react';
import useIsMobile from './UseIsMobile';
import { apiPostTweet, apiGetTweet } from '../server';

function Footer({ tag }) {
  const isMobile = useIsMobile();

  const [message, setMessage] = useState('');
  const [focused, setFocused] = useState(false);
  const [mobileExpanded, setMobileExpanded] = useState(false);
  const [taggedTweet, setTaggedTweet] = useState(null);
  const textareaRef = useRef(null);

  const handleClickOutside = (event) => {
    if (textareaRef.current && !textareaRef.current.contains(event.target)) {
      setFocused(false);
    }
  };

  useEffect(() => {
    if (textareaRef?.current) {
      textareaRef.current?.focus();
      textareaRef.current.selectionStart = textareaRef.current.selectionEnd = textareaRef.current.value.length;
    }
  }, [mobileExpanded, focused]);

  useEffect(() => {
    if (!tag) return;
    const match = /^id\/([a-z0-9-]{36})$/.exec(tag);
    if (!match) return setTaggedTweet(null);

    const id = match[1];
    (async () => {
      try {
        const { data } = await apiGetTweet(id);
        setTaggedTweet(data.tweet);
      } catch (error) {
        setTaggedTweet(null);
      }
    })();
  }, [tag]);

  useEffect(() => {
    // Add click listener when the component mounts
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup listener when the component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // --- FOCUS & BLUR ---

  // On focus:
  //  - Mobile => fullscreen
  //  - Desktop => set focused=true (expands & auto-resizes)
  const handleFocus = () => {
    if (isMobile) {
      setMobileExpanded(true);
    } else {
      setTimeout(() => {
        setFocused(true);
      }, 100);
    }
  };

  // On blur:
  //  - Mobile => exit fullscreen
  //  - Desktop => set focused=false (collapse back to single line)
  const handleBlur = () => {
    if (isMobile) {
      setMobileExpanded(false);
    }
    setTimeout(() => {
      setFocused(false);
    }, 0);
  };

  // --- BUTTONS ---

  // Cancel = clear text (optional) and exit mobile fullscreen
  const handleOffFocus = () => {
    // setMessage(''); // Uncomment if you DO want to clear on Cancel
    setMobileExpanded(false);
  };

  // Send = log & clear text (optional), exit mobile fullscreen
  const handleSend = () => {
    if (message.trim().length < 2) {
      return;
    }

    const filatticeLocationsString = localStorage.getItem('filatticeLocations');
    const filatticeLocations = filatticeLocationsString ? JSON.parse(filatticeLocationsString) : [];

    const userLocationString = localStorage.getItem('userLocation');
    const userLocation = userLocationString ? JSON.parse(userLocationString) : {};

    const tags = [tag];
    const taggedTweetMainTag = taggedTweet?.tags.find(t => {
      const match = t.match(/^in\/[a-z0-9-/]+/);
      return match && match[0];
    });

    const mainTag = tag.startsWith('in/') ? tag : (taggedTweetMainTag || localStorage.getItem('tag'))

    if (mainTag) {
      filatticeLocations.forEach(point => {
        tags.push(`${mainTag}@${point}`);
      });
    }

    apiPostTweet({
      text: message.trim(),
      tags: tags,
      longitude: userLocation.longitude || null,
      latitude: userLocation.latitude || null,
    });

    setMessage('');
    setMobileExpanded(false);
    setFocused(false);
  };

  // --- AUTO-RESIZING (Desktop) ---

  useEffect(() => {
    // If no textarea, bail out
    if (!textareaRef.current) return;

    const textArea = textareaRef.current;

    // On desktop while focused => auto-resize up to 10 lines
    // If not focused => revert to single-line
    if (!isMobile) {
      if (focused) {
        textArea.style.height = 'auto'; // reset first
        const maxHeight = 240; // ~10 lines
        const newHeight = Math.min(textArea.scrollHeight, maxHeight);
        textArea.style.height = `${newHeight}px`;
      } else {
        // When losing focus, revert to a single line
        textArea.style.height = 'auto';
      }
    }

    setTimeout(() => { textareaRef?.current?.focus(); }, 10);

  }, [isMobile, focused, message]);

  // --- RENDER ---

  // Determine if the send button should be enabled
  const isSendEnabled = message.trim().length >= 2;

  // 1) Fullscreen (Mobile)
  if (isMobile && mobileExpanded) {
    return (
      <div className="fixed inset-0 bg-white dark:bg-gray-900 flex flex-col z-20">
        {/* Header with Cancel & Send */}
        <div className="flex items-center justify-between p-2 border-b border-gray-300 dark:border-gray-700">
          <button
            onClick={handleOffFocus}
            className="
              flex items-center justify-center
              p-2 rounded-full
              bg-gray-200 dark:bg-gray-700
              text-gray-700 dark:text-gray-100
              hover:bg-gray-300 dark:hover:bg-gray-600
            "
            aria-label="Cancel"
          >
            {/* Icon: Arrow Left */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none" viewBox="0 0 24 24"
              strokeWidth={2} stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round" strokeLinejoin="round"
                d="M10.5 19.5l-7.5-7.5 7.5-7.5M3 12h18"
              />
            </svg>
          </button>

          <button
            onClick={handleSend}
            disabled={!isSendEnabled}
            className={`
              rounded-full
              flex items-center justify-center
              ${isSendEnabled ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'}
            `}
            aria-label="Send"
          >
            <img src="/img/send-64.png" alt="Send" className="w-8 h-8" />
          </button>
        </div>

        {/* Fullscreen Text Area */}
        <div className="flex-1 p-2">
          <textarea
            className="
              w-full resize-none
              bg-white-100 dark:bg-gray-900
              rounded-md p-4
              text-gray-700 dark:text-gray-100
              focus:outline-none
            "
            rows={7}
            ref={textareaRef}
            value={message}
            onBlur={() => {
              setTimeout(() => {
                if (mobileExpanded) handleOffFocus();
              }, 20);
            }}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
      </div>
    );
  }

  // 2) Standard Footer (Desktop or Mobile Collapsed)
  return (
    <footer
      className="fixed bottom-0 left-0 w-full
        bg-white dark:bg-gray-800
        border-t border-gray-300 dark:border-gray-700
      "
    >
      <div className="max-w-screen-lg mx-auto flex items-center px-4 py-2">
        {focused ?
          <textarea
            ref={textareaRef}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            rows={1} // ensures single-line by default
            className={`
            flex-1
            resize-none
            bg-gray-100 dark:bg-gray-700
            rounded-md p-2 leading-6
            text-gray-700 dark:text-gray-100
            transition-all 
            focus:outline-none
            ${isMobile ? 'h-9 overflow-hidden' : ''}
            ${focused ? 'overflow-y-auto' : 'overflow-hidden'}
          `}
            placeholder="Type your message..."
          /> :
          <input
            id="message-box"
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className="
                flex-1
                bg-gray-100 dark:bg-gray-700
                rounded-md p-2
                text-gray-700 dark:text-gray-100
                focus:outline-none
              "
            placeholder="Type your message..."
          />
        }

        <button
          onMouseDown={handleSend}
          disabled={!isSendEnabled}
          className={`
            ml-2 py-2 
            rounded-full
            flex items-center justify-center
            ${isSendEnabled ? 'cursor-pointer' : 'cursor-not-allowed opacity-50'}
          `}
          aria-label="Send"
        >
          <img src="/img/send-64.png" alt="Send" className="w-8 h-8" />
        </button>
      </div>
    </footer>
  );
}

export default Footer;